import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';
import { HowItWorksModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from '../_shared/MarginSetting';

export default function ImageModuleSettings({
    module,
    onChange,
}: ModuleProps<HowItWorksModuleType>) {
    const { FrontSelect, FrontColorPicker } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, process, background } = module.data;

    return (
        <>
            <Row>
                <Col width={[6, 6, 12]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24">
                    <FrontColorPicker
                        value={background}
                        onChange={change('background')}
                        medium
                        style={{ width: '100%' }}
                        label="Background color"
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24">
                    <FrontSelect
                        label="Prosess"
                        value={process}
                        medium
                        style={{ width: '100%' }}
                        options={[
                            {
                                name: 'Elektriker jobbinstallasjon',
                                value: 'electricianJobInstallation',
                            },
                            { name: 'Videobefaring', value: 'videoInspection' },
                        ]}
                        onChange={change('process')}
                    />
                </Col>
            </Row>
        </>
    );
}
