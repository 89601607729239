import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import { JobInfo, EnovaSupport } from '/types/jobTemplateTypes';
import JobBadge from './JobBadge';

interface Props extends HTMLAttributes<HTMLDivElement> {
    badgeInfo: JobInfo['badge'];
    enovaSupport: EnovaSupport;
}

export default function JobCardBadges({
    badgeInfo,
    enovaSupport,
    ...rest
}: Props) {
    if (!badgeInfo && !enovaSupport?.enabled) return null;

    return (
        <Wrapper {...rest}>
            {badgeInfo?.newJob && <JobBadge type="new" />}
            {enovaSupport?.enabled && <JobBadge type="enova" />}
            {badgeInfo?.brandImage && (
                <JobBadge type="image" imageSrc={badgeInfo?.brandImage} />
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
`;
