import { memo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';
import Link from 'next/link';

import Container from '/components/Container';
import { Text, Button, Image, ui, config, Row, Col, _ } from '/front';
import { changeArrayItemPosition } from '/lib/helpers';

const StoryLink = ({ href, editMode, children }) => {
    if (editMode) return <a className="story-image-link mb-48">{children}</a>;

    return (
        <Link
            prefetch={false}
            href={href}
            passHref
            className="story-image-link mb-48"
        >
            {children}
        </Link>
    );
};

export default memo(function CustomerStoriesModule({
    module,
    onChange,
    addAction,
}) {
    const { margin, items } = module.data;

    const { editMode, FrontActions, FrontSelect, FrontModal } = ui();

    const [editedItemIndex, setEditedItemIndex] = useState(null);

    const newItem = {
        image: null,
        title: '',
        description: '',
        button: null,
        theme: 'default', // or "wide"
    };

    useEffect(() => {
        if (editMode && !items.length) addItem();
    }, [editMode]);

    useEffect(() => {
        if (editMode) addAction('onAdd', addItem);
    }, [module.data.items, editMode]); // update action everythime module items change to ensure access to up to date module data in the callback

    const addItem = () =>
        onChange({
            data: { ...module.data, items: [...module.data.items, newItem] },
        });

    const removeItem = (index) =>
        onChange({
            data: {
                ...module.data,
                items: module.data.items.filter((_, i) => i !== index),
            },
        });

    const changeItem = curry((index, key, value) =>
        onChange({
            data: {
                ...module.data,
                items: items.map((item, i) => {
                    if (i !== index) return item;
                    return { ...item, [key]: value };
                }),
            },
        })
    );

    const moveItems = useCallback(
        (currentIndex, dir) => {
            const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
            const newItems = changeArrayItemPosition(
                items,
                currentIndex,
                newIndex
            );
            onChange({ data: { ...module.data, items: newItems } });
        },
        [items]
    );

    return (
        <StyledContainer className={margin}>
            <div className="story-items">
                {items.map((item, index) => (
                    <div
                        className={`story-item ${
                            editMode ? 'has-front-actions' : ''
                        } theme-${item.theme}`}
                        key={index}
                    >
                        <StoryLink
                            href={config.pageUrl(item.button).href}
                            editMode={editMode}
                        >
                            <Image
                                image={item.image}
                                src={config
                                    .imageUrl(item.image)
                                    .width(
                                        item.theme === 'default' ? 560 : 1160
                                    )
                                    .height(415)
                                    .url()}
                                onChange={changeItem(index)('image')}
                                ariaLabel={item.title}
                                title={item.title}
                            />
                        </StoryLink>
                        <Text
                            value={item.title}
                            onChange={changeItem(index)('title')}
                            placeholder="Title"
                            multiline
                            className={`paragraph-medium mb-16`}
                            tag="p"
                        />
                        <Text
                            value={item.description}
                            onChange={changeItem(index)('description')}
                            placeholder="Description"
                            multiline
                            className={`heading-large mb-48`}
                            tag="p"
                        />
                        <div className="button-container">
                            <Button
                                className="button"
                                value={item.button}
                                onChange={changeItem(index)('button')}
                                link={config.pageUrl(item.button)}
                            >
                                {item.button?.title || 'Les mer'}
                            </Button>
                        </div>
                        <FrontActions
                            label="Story"
                            align="top-left"
                            onMoveLeft={
                                index ? () => moveItems(index, -1) : null
                            }
                            onMoveRight={
                                index < items.length - 1
                                    ? () => moveItems(index, 1)
                                    : null
                            }
                            onEdit={() => setEditedItemIndex(index)}
                            onDelete={
                                items.length <= 1
                                    ? null
                                    : () => removeItem(index)
                            }
                        />
                    </div>
                ))}
            </div>
            {editedItemIndex !== null && (
                <FrontModal
                    onClose={() => setEditedItemIndex(null)}
                    width="500px"
                    height="230px"
                    title={_('Story settings')}
                >
                    <Row>
                        <Col width={12} className="mb-24">
                            <FrontSelect
                                label="Story item theme"
                                value={
                                    module.data.items[editedItemIndex]?.theme
                                }
                                onChange={changeItem(editedItemIndex)('theme')}
                                options={['default', 'wide']}
                                style={{ width: '100%' }}
                                medium
                            />
                        </Col>
                    </Row>
                </FrontModal>
            )}
        </StyledContainer>
    );
});

const StyledContainer = styled(Container)`
    .story-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .story-item {
            width: calc(50% - 16px);
            margin-bottom: 24px;
            @media (max-width: 767px) {
                width: 100%;
            }
            &.theme-default {
                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                    @media (max-width: 767px) {
                        margin-bottom: 24px;
                    }
                }
            }
            &.theme-wide {
                width: 100%;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .story-image-link {
                border-radius: 12px;
                overflow: hidden;
                display: block;
                img {
                    display: block;
                    width: 100%;
                }
            }
            .button-container {
                display: inline-block;
            }
        }
    }
`;
