import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';

export default function CardsModuleSettings({ module, onChange }) {
    const { FrontSelect, FrontColorPicker } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, iconType, iconColor, columnCount } = module.data;

    return (
        <>
            <Row>
                <Col width={6} className="mb-24">
                    <FrontSelect
                        value={margin}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'mb-32' },
                            { name: 'Medium (48px)', value: 'mb-48' },
                            { name: 'Large (60px)', value: 'mb-60' },
                            { name: 'Extra large (80px)', value: 'mb-80' },
                        ]}
                        onChange={change('margin')}
                        medium
                        style={{ width: '100%' }}
                        label="Bottom margin"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <FrontSelect
                        value={columnCount}
                        options={['1', '2']}
                        onChange={change('columnCount')}
                        medium
                        style={{ width: '100%' }}
                        label="Column count"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <FrontSelect
                        value={iconType}
                        options={[
                            { name: 'Spoton logo', value: 'spoton-logo' },
                            { name: 'Number', value: 'number' },
                        ]}
                        onChange={change('iconType')}
                        medium
                        style={{ width: '100%' }}
                        label="Icon type"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <FrontColorPicker
                        value={iconColor}
                        onChange={change('iconColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Icon color"
                    />
                </Col>
            </Row>
        </>
    );
}
