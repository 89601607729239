import { memo, useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';

import Container from '/components/Container';
import { Text, config, ui, Button, _, Row, Col } from '/front';
import Carousel from '/components/layout/Carousel';
import { changeArrayItemPosition } from '/lib/helpers';

export default memo(function CarouseLModule({ module, onChange }) {
    const {
        editMode,
        FrontActions,
        FrontModal,
        FrontSelect,
        FrontSlider,
        FrontImageInput,
    } = ui();

    const { margin, items } = module.data;

    const carouselRef = useRef(null);

    const [editedItemIndex, setEditedItemIndex] = useState(null);

    const changeItem = curry((index, key, value) =>
        onChange({
            data: {
                ...module.data,
                items: items.map((item, i) => {
                    if (i !== index) return item;
                    return { ...item, [key]: value };
                }),
            },
        })
    );

    const emptyItem = {
        image: null,
        title: '',
        button: '',
        badge: null,
        overlayColor: 'black',
        overlayOpacity: 0,
        textColor: 'white',
    };

    useEffect(() => {
        if (editMode && !items.length) {
            onChange({ data: { ...module.data, items: [emptyItem] } });
        }
    }, [editMode]);

    const onAddSlide = () => {
        onChange({ data: { ...module.data, items: [...items, emptyItem] } });
        if (carouselRef.current.move) {
            setTimeout(() => carouselRef.current.move('LAST'), 500);
        }
    };

    const onRemoveSlide = (index) => {
        onChange({
            data: {
                ...module.data,
                items: items.filter((_, i) => i !== index),
            },
        });
    };

    const moveItems = useCallback(
        (currentIndex, dir) => {
            const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
            const newItems = changeArrayItemPosition(
                module.data.items,
                currentIndex,
                newIndex
            );
            onChange({ data: { ...module.data, items: newItems } });
        },
        [module.data.items]
    );

    if (!editMode && !items.length) return null;

    return (
        <Wrapper className={margin}>
            <Carousel withArrows withNumbers ref={carouselRef}>
                {module.data.items.map((item, index) => (
                    <CarouselItem
                        key={index}
                        imageUrl={config.imageUrl(item.image)}
                    >
                        <div
                            className="image-overlay"
                            style={{
                                background: item.overlayColor || 'black',
                                opacity: (item.overlayOpacity || 0) / 100,
                            }}
                        />
                        <div className="item-text-layer">
                            <Text
                                value={item.title}
                                onChange={changeItem(index)('title')}
                                placeholder="Title"
                                multiline
                                className={`item-title heading-large mb-32 text-align-center`}
                                style={{ color: item.textColor || 'white' }}
                                placeholderStyle={{ opacity: '.8' }}
                                tag="div"
                            />
                            <Button
                                className="button"
                                value={item.button}
                                onChange={changeItem(index)('button')}
                                link={config.pageUrl(item.button)}
                            >
                                {item.button?.title || 'button'}
                            </Button>
                        </div>
                        <FrontActions
                            label="Slide"
                            align="top-left"
                            onMoveLeft={
                                index ? () => moveItems(index, -1) : null
                            }
                            onMoveRight={
                                index < items.length - 1
                                    ? () => moveItems(index, 1)
                                    : null
                            }
                            onAdd={onAddSlide}
                            onEdit={() => setEditedItemIndex(index)}
                            onDelete={
                                items.length <= 1
                                    ? null
                                    : () => onRemoveSlide(index)
                            }
                        />
                    </CarouselItem>
                ))}
            </Carousel>
            {editedItemIndex !== null && (
                <FrontModal
                    onClose={() => setEditedItemIndex(null)}
                    width="500px"
                    height="300px"
                    title={_('Image settings')}
                >
                    <Row>
                        <Col width={6} className="mb-24">
                            <FrontSelect
                                label="Overlay color"
                                value={
                                    module.data.items[editedItemIndex]
                                        ?.overlayColor
                                }
                                onChange={changeItem(editedItemIndex)(
                                    'overlayColor'
                                )}
                                options={['black', 'white']}
                                style={{ width: '100%' }}
                                medium
                            />
                        </Col>
                        <Col width={6} className="mb-24">
                            <FrontSelect
                                label="Text color"
                                value={
                                    module.data.items[editedItemIndex]
                                        ?.textColor
                                }
                                onChange={changeItem(editedItemIndex)(
                                    'textColor'
                                )}
                                options={[
                                    { name: 'black', value: '#1F1A22' },
                                    { name: 'white', value: 'white' },
                                ]}
                                style={{ width: '100%' }}
                                medium
                            />
                        </Col>
                        <Col width={6} className="mb-24">
                            <FrontSlider
                                label="Overlay opacity"
                                value={
                                    module.data.items[editedItemIndex]
                                        ?.overlayOpacity || 0
                                }
                                onChange={changeItem(editedItemIndex)(
                                    'overlayOpacity'
                                )}
                                medium
                            />
                        </Col>
                        <Col width={6} className="mb-24">
                            <FrontImageInput
                                value={module.data.items[editedItemIndex].image}
                                onChange={changeItem(editedItemIndex)('image')}
                                label="Image"
                                width={275}
                                height={100}
                            />
                        </Col>
                    </Row>
                </FrontModal>
            )}
        </Wrapper>
    );
});

const Wrapper = styled(Container)`
    border-radius: 12px;
    overflow: hidden;
    .arrow-left,
    .arrow-right {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        background: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        box-shadow: none !important;
        transition: background 0.3s ease-in-out;
        transform: translateY(-50%);
        svg {
            min-width: 24px;
            min-height: 24px;
            path {
                fill: #fff;
            }
        }
        @media (pointer: fine) {
            &:hover {
                background: rgba(0, 0, 0, 0.7);
            }
        }
    }
    .arrow-left {
        left: 32px !important;
    }
    .arrow-right {
        right: 32px !important;
    }
    .carousel-arrows {
        @media (max-width: 767px) {
            display: block;
            width: 100%;
            > button {
                transform: scale(55%);
                top: auto;
                bottom: 5px;
                &:first-child {
                    left: 5px !important;
                }
                &:last-child {
                    right: 5px !important;
                }
            }
        }
    }
    .carousel-numbers {
        @media (max-width: 767px) {
            right: auto;
            left: 50%;
            transform: translateX(-50%) scale(0.9);
        }
    }
`;

const CarouselItem = styled.div`
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 409px;
    background-image: image-set(
        url('${(props) =>
                props.imageUrl.width(1160).height(409).ratio(1).url()}')
            1x,
        url('${(props) =>
                props.imageUrl.width(1160).height(409).ratio(2).url()}')
            2x
    );
    @media (min-width: 768px) and (max-width: 1024px) {
        background-image: image-set(
            url('${(props) =>
                    props.imageUrl.width(1024).height(409).ratio(1).url()}')
                1x,
            url('${(props) =>
                    props.imageUrl.width(1024).height(409).ratio(2).url()}')
                2x
        );
    }
    @media (max-width: 767px) {
        height: 327px;
        background-image: image-set(
            url('${(props) =>
                    props.imageUrl.width(767).height(327).ratio(1).url()}')
                1x,
            url('${(props) =>
                    props.imageUrl.width(767).height(327).ratio(2).url()}')
                2x
        );
    }
    @media (max-width: 480px) {
        height: 327px;
        background-image: image-set(
            url('${(props) =>
                    props.imageUrl.width(480).height(327).ratio(1).url()}')
                1x,
            url('${(props) =>
                    props.imageUrl.width(480).height(327).ratio(2).url()}')
                2x
        );
    }
    .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .item-text-layer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 80%;
        text-align: center;
        .button {
            @media (min-width: 768px) and (max-width: 1024px) {
                height: 54px;
                min-height: 54px;
                font-size: 17px;
                line-height: 22px;
                padding: 0 16px 2px;
            }
            @media (max-width: 767px) {
                height: 36px;
                min-height: 36px;
                font-size: 17px;
                line-height: 22px;
                padding: 0 16px 1px;
            }
        }
    }
`;
