import curry from 'ramda/src/curry';
import styled from 'styled-components';

import { ui, Row, Col } from '/front';
import { HeroModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';
import moduleConfig from './';
import { createDataValueGetter } from '/front/lib/helpers';
import NumberSlider from '/components/ui/NumberSlider';
import VerticalPaddingSetting from '../_shared/VerticalPaddingSetting';

export default function HeroModuleSettings({
    module,
    onChange,
}: ModuleProps<HeroModuleType>) {
    const {
        FrontColorPicker,
        FrontSelect,
        FrontSwitch,
        FrontImageInput,
        FrontLabel,
    } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const dataValue = createDataValueGetter(module, moduleConfig);

    return (
        <>
            <Row className="ft-module-settings-section mb-12">
                <Col width={6}>
                    <MarginSetting
                        margin={dataValue('margin')}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={2}>
                    <FrontSwitch
                        on={dataValue('topBleed')}
                        onChange={change('topBleed')}
                        style={{ marginTop: '5px' }}
                        label="Top bleed"
                    />
                </Col>
                <Col width={4}>
                    <VerticalPaddingSetting
                        verticalPadding={dataValue('verticalPadding')}
                        onChange={change('verticalPadding')}
                    />
                </Col>
            </Row>
            <Row className="ft-module-settings-section mb-12">
                <Col width={6} className="mb-12">
                    <FrontSelect
                        value={dataValue('textColor')}
                        options={[
                            { name: 'Black', value: 'black' },
                            { name: 'White', value: 'white' },
                        ]}
                        onChange={change('textColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Text color"
                    />
                </Col>
                <Col width={6}>
                    <FrontSwitch
                        on={dataValue('withImage')}
                        onChange={change('withImage')}
                        style={{ marginTop: '5px' }}
                        label="Image below / above text"
                    />
                </Col>
                <Col width={6}>
                    <FrontSelect
                        value={dataValue('imagePlacement')}
                        options={[
                            { name: 'Below text', value: 'below-text' },
                            { name: 'Above text', value: 'above-text' },
                        ]}
                        onChange={change('imagePlacement')}
                        medium
                        style={{ width: '100%' }}
                        label="Image placement"
                    />
                </Col>
                <Col width={[6]}>
                    <FrontSwitch
                        on={dataValue('imageRoundedCorners')}
                        onChange={change('imageRoundedCorners')}
                        style={{ marginTop: '5px' }}
                        label="Rounded corners"
                    />
                </Col>
            </Row>
            <Row className="ft-module-settings-section mb-12">
                <Col width={6} className="mb-24">
                    <Row>
                        <Col width={12} className="mb-12">
                            <FrontColorPicker
                                value={dataValue('backgroundColor')}
                                onChange={change('backgroundColor')}
                                medium
                                style={{ width: '100%' }}
                                label="Background color"
                            />
                        </Col>
                        <Col width={12} className="mb-12">
                            <FrontColorPicker
                                value={dataValue('backgroundOverlayColor')}
                                onChange={change('backgroundOverlayColor')}
                                medium
                                style={{ width: '100%' }}
                                label="Background overlay color"
                            />
                        </Col>
                        <OverlaySettingsCol width={12}>
                            <FrontLabel>Background overlay opacity</FrontLabel>
                            <NumberSlider
                                name="overlay-opacity"
                                value={dataValue('backgroundOverlayOpacity')}
                                onChange={change('backgroundOverlayOpacity')}
                                minValue={0}
                                maxValue={80}
                            />
                        </OverlaySettingsCol>
                    </Row>
                </Col>
                <Col width={[6]}>
                    <FrontImageInput
                        value={dataValue('backgroundImage')}
                        onChange={change('backgroundImage')}
                        label="Background image"
                        width={275}
                        height={150}
                    />
                </Col>
            </Row>
        </>
    );
}

const OverlaySettingsCol = styled(Col)`
    .number-slider {
        > div {
            height: 30px;
        }
        .min-max,
        .value-text {
            display: none;
        }
        input {
            &::-webkit-slider-thumb {
                width: 25px;
                height: 25px;
            }
            &::-moz-range-thumb {
                width: 25px;
                height: 25px;
            }
        }
    }
`;
