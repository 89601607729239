import curry from 'ramda/src/curry';
import styled from 'styled-components';

import { ui, Row, Col } from '/front';
import { ArticleIntroModuleType } from './types';
import MarginSetting from './../_shared/MarginSetting';
import { ModuleProps } from '/front/types/frontModuleType';
import NumberSlider from '/components/ui/NumberSlider';

export default function HeroModuleSettings({
    module,
    onChange,
}: ModuleProps<ArticleIntroModuleType>) {
    const { FrontSelect, FrontImageInput, FrontLabel, FrontSwitch } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        textColor,
        margin,
        image,
        hideDate,
        overlayOpacity,
        overlayColor,
        withButtons,
    } = module.data;

    return (
        <>
            <Row>
                <Col width={6} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <FrontSelect
                        value={textColor}
                        options={['black', 'white']}
                        onChange={change('textColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Text color"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <FrontImageInput
                        value={image}
                        onChange={change('image')}
                        label="Background image"
                        width={275}
                        height={150}
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <OverlaySettings>
                        <FrontSelect
                            value={overlayColor || 'white'}
                            options={['black', 'white']}
                            onChange={change('overlayColor')}
                            medium
                            style={{ width: '100%' }}
                            className="mb-24"
                            label="Overlay color"
                        />
                        <FrontLabel>Overlay opacity</FrontLabel>
                        <NumberSlider
                            name="overlay-opacity"
                            value={overlayOpacity || 0}
                            onChange={change('overlayOpacity')}
                            minValue={0}
                            maxValue={80}
                        />
                    </OverlaySettings>
                </Col>
                <Col width={6} className="mb-24">
                    <FrontSwitch
                        on={hideDate}
                        onChange={change('hideDate')}
                        label={'Hide date'}
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <FrontSwitch
                        on={withButtons}
                        onChange={change('withButtons')}
                        label={'Call to action buttons'}
                    />
                </Col>
            </Row>
        </>
    );
}

const OverlaySettings = styled.div`
    .number-slider {
        > div {
            height: 40px;
        }
        .min-max,
        .value-text {
            display: none;
        }
    }
`;
