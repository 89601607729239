import { useEffect, useState, ReactNode, HTMLAttributes } from 'react';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';

import Button from './buttons/Button';
import Icon from '/components/ui/Icon';
import Heading from '/components/ui/typography/Heading';
import Description from '/components/ui/typography/Description';
import { colors, effects } from '/css';
import { isServer } from '/lib/helpers';

interface Props
    extends Omit<HTMLAttributes<HTMLElement>, 'title' | 'onToggle'> {
    title: string | ReactNode;
    description?: string | ReactNode;
    children?: ReactNode;
    grey?: boolean;
    darkGrey?: boolean;
    raised?: boolean;
    bordered?: boolean;
    bold?: boolean;
    disabled?: boolean;
    expanded?: boolean;
    rightSection?: ReactNode | string;
    onToggle?: (expanded: boolean) => void;
}

const Toggle = ({
    title,
    description,
    children,
    grey,
    darkGrey,
    raised,
    bordered,
    bold,
    disabled,
    expanded,
    rightSection,
    onToggle,
    ...rest
}: Props) => {
    const hash = isServer ? '' : (location?.hash || '').replace('#', '');

    const [isExpanded, setExpanded] = useState(expanded);

    const scroll = () => {
        if (rest.id) {
            document
                .getElementById(rest.id)
                ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        if (hash && hash === rest.id) {
            setTimeout(scroll, 1000);
        }
    }, []);

    useEffect(() => {
        if (!children && isExpanded) setExpanded(false);
    }, [children]);

    const toggle = () => {
        if (disabled) return;
        if (onToggle) onToggle(!isExpanded);
        setExpanded(!isExpanded);
    };

    const padded = grey || darkGrey || raised || bordered;

    const icon = `dropdown-small${isExpanded ? '-open' : ''}`;

    return (
        <Wrapper
            grey={grey}
            darkGrey={darkGrey}
            raised={raised}
            isDisabled={disabled}
            bordered={bordered}
            {...rest}
        >
            <div
                className={[
                    'title',
                    padded && 'pt-24 pb-24 pl-32 pr-32',
                    isExpanded && 'expanded',
                    description && 'with-description',
                ]
                    .filter((c) => c)
                    .join(' ')}
                onClick={toggle}
            >
                <ToggleTitle
                    title={title}
                    description={description}
                    bold={bold}
                />
                {!disabled && (
                    <Button
                        variant="tertiary"
                        onClick={() => {}}
                        size="small"
                        className="toggle-button"
                    >
                        <Icon icon={icon} />
                    </Button>
                )}
                {rightSection}
            </div>
            <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
                <div
                    className={['details', padded && 'pb-24 pl-32 pr-32']
                        .filter((c) => c)
                        .join(' ')}
                >
                    {children}
                </div>
            </AnimateHeight>
        </Wrapper>
    );
};

export default Toggle;

interface ToggleTitleProps {
    title: string | ReactNode;
    description?: string | ReactNode;
    bold?: boolean;
}
const ToggleTitle = ({ title, description, bold }: ToggleTitleProps) => {
    if (!description && typeof title !== 'string') return <>{title}</>;

    if (!description)
        return (
            <Heading size="medium" as="div">
                {title}
            </Heading>
        );

    return (
        <div className="title-description">
            <Description style={bold ? { fontSize: '17px' } : undefined}>
                {description}
            </Description>
            {bold ? (
                <Heading size="medium" as="div">
                    {title}
                </Heading>
            ) : (
                <div className="paragraph-small">{title}</div>
            )}
        </div>
    );
};

interface WrapperProps {
    isDisabled?: boolean;
    grey?: boolean;
    darkGrey?: boolean;
    raised?: boolean;
    bordered?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
        position: relative;
        .toggle-button {
            padding: 0;
            height: 36px;
            width: 36px;
            min-width: 36px;
            svg {
                position: relative;
                bottom: -1px;
                margin-right: 0;
            }
        }
        &:hover {
            .toggle-button {
                border: 3px solid ${colors.purple};
            }
        }
        &.with-description {
            .title-description {
                transition: all 0.2s ease-in-out;
            }
            .paragraph-small {
                padding-top: 4px;
            }
            .toggle-button {
                border-color: transparent !important;
                svg {
                    transform: scale(1.3);
                }
            }
        }
    }

    ${(props) =>
        props.grey &&
        `
        border-radius: 12px;
        background: ${colors.grey2};
    `}

    ${(props) =>
        props.darkGrey &&
        `
        border-radius: 12px;
        background: #ECEDF0!important;
        box-shadow:none!important;
    `}

    ${(props) =>
        props.raised &&
        `
        border-radius: 12px;
        background: #fff;
        ${effects.shadow};
    `}

    ${(props) =>
        props.bordered &&
        `
        border: 3px solid ${colors.grey3};
        border-radius: 12px;
    `}
`;
