import { ui } from '/front';

export type Margin = '' | 'mb-32' | 'mb-48' | 'mb-60' | 'mb-80';

interface Props {
    margin: Margin;
    onChange: (val: Margin) => void;
}

export default function MarginSetting({ margin, onChange }: Props) {
    const { FrontSelect } = ui();

    return (
        <FrontSelect
            value={margin}
            options={[
                { name: 'None', value: '' },
                { name: 'Extra small (24px)', value: 'mb-24' },
                { name: 'Small (32px)', value: 'mb-32' },
                { name: 'Medium (48px)', value: 'mb-48' },
                { name: 'Large (60px)', value: 'mb-60' },
                { name: 'Extra large (80px)', value: 'mb-80' },
                { name: 'XXL (120px)', value: 'mb-120' },
            ]}
            onChange={onChange}
            medium
            style={{ width: '100%' }}
            label="Bottom margin"
        />
    );
}
