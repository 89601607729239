import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';

export default function PopularJobsModuleSettings({ module, onChange }) {
    const { FrontSelect, FrontInput } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, trackingId } = module.data;

    return (
        <>
            <Row>
                <Col width={[6, 6, 12]} className="mb-24">
                    <FrontSelect
                        value={margin}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'mb-32' },
                            { name: 'Medium (48px)', value: 'mb-48' },
                            { name: 'Large (60px)', value: 'mb-60' },
                            { name: 'Extra large (80px)', value: 'mb-80' },
                        ]}
                        onChange={change('margin')}
                        medium
                        style={{ width: '100%' }}
                        label="Bottom margin"
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24">
                    <FrontInput
                        value={trackingId}
                        onChange={change('trackingId')}
                        medium
                        style={{ width: '100%' }}
                        label="Tracking ID"
                    />
                </Col>
            </Row>
        </>
    );
}
