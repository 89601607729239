import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';
import { TimelineModuleType, CallToAction } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';

export default function TimelineModuleSettings({
    module,
    onChange,
}: ModuleProps<TimelineModuleType>) {
    const { FrontSelect } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, callToAction, theme } = module.data;

    return (
        <>
            <Row>
                <>
                    <Col width={[12]} className="mb-24">
                        <MarginSetting
                            margin={margin}
                            onChange={change('margin')}
                        />
                    </Col>
                    <Col width={[12]} className="mb-24">
                        <FrontSelect
                            value={theme || 'wide'}
                            options={[
                                {
                                    name: 'Wide (text on the left, timeline on the right) ',
                                    value: 'wide',
                                },
                                {
                                    name: 'Narrow (text and timeine in th ecenter)',
                                    value: 'narrow',
                                },
                            ]}
                            onChange={change('theme')}
                            medium
                            style={{ width: '100%' }}
                            label="Theme"
                        />
                    </Col>

                    <Col width={[12]} className="mb-24">
                        <FrontSelect
                            value={callToAction}
                            options={[
                                { name: 'Button', value: CallToAction.BUTTON },
                                {
                                    name: 'Solar address search',
                                    value: CallToAction.SOLAR,
                                },
                                {
                                    name: 'Solar address search & button',
                                    value: CallToAction.SOLAR_BUTTON,
                                },
                                {
                                    name: 'Solar address search & contact form',
                                    value: CallToAction.SOLAR_CONTACT,
                                },
                            ]}
                            onChange={change('callToAction')}
                            medium
                            style={{ width: '100%' }}
                            label="Call to action type"
                        />
                    </Col>
                </>
            </Row>
        </>
    );
}
