import styled from 'styled-components';

import { HowItWorksModuleType } from '../types';
import BrowseIllustration from '../illustrations/BrowseIllustration';
import InstallationIllustration from '../illustrations/InstallationIllustration';
import TimeslotIllustration from '../illustrations/TimeslotIllustration';
import ElectricianIllustration from '../illustrations/ElectricianIllustration';
import Container from '/components/Container';
import { colors } from '/css';
import { Text } from '/front';
import Button from '/components/ui/buttons/Button';
import PaymentIllustration from '/components/modules/howItWorks/illustrations/PaymentIllustration';

const illustrationMap = {
    configuration: BrowseIllustration,
    timeSelection: TimeslotIllustration,
    electrician: ElectricianIllustration,
    installation: InstallationIllustration,
    payment: PaymentIllustration,
};

type Data = HowItWorksModuleType['data']['electricianJobInstallation'];

interface Props {
    data: Data;
    onChange: (d: Data) => void;
}

export default function ElectricianJobInstallation({ data, onChange }: Props) {
    const { steps } = data;

    const onTextChange =
        (stepKey: string, key: 'title' | 'description') => (val: string) => {
            onChange({
                ...data,
                steps: data.steps.map((s) =>
                    s.key === stepKey ? { ...s, [key]: val } : s
                ),
            });
        };

    return (
        <Wrapper>
            <Container>
                {steps.map((step, i) => {
                    const Illustration = illustrationMap[step.key];
                    return (
                        <div
                            key={step.key}
                            className={`step-card step-${step.key} ${
                                !(i % 2) ? 'odd' : 'even'
                            }`}
                        >
                            <div className="text">
                                <Text
                                    value={step.title}
                                    onChange={onTextChange(step.key, 'title')}
                                    placeholder="1. ..."
                                    multiline
                                    className={`heading-large mb-24`}
                                    tag="h3"
                                />
                                <Text
                                    value={step.description}
                                    onChange={onTextChange(
                                        step.key,
                                        'description'
                                    )}
                                    placeholder="..."
                                    multiline
                                    tag="p"
                                    className={`paragraph-medium`}
                                />
                                {step.key === 'configuration' && (
                                    <Button
                                        link={{ href: '/nettbutikk/alle' }}
                                        variant="secondary"
                                        size="medium"
                                    >
                                        Se jobbene vi tilbyr
                                    </Button>
                                )}
                            </div>
                            <Illustration />
                        </div>
                    );
                })}
                <div className="bottom-cta">
                    <Button
                        link={{ href: '/nettbutikk/alle' }}
                        variant="primary"
                        size="large"
                    >
                        Se jobbene vi tilbyr
                    </Button>
                </div>
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .step-card {
        transition: filter 0.3s ease-in-out;
        padding: 0 80px;
        border: 3px solid transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 24px;
        margin-bottom: 64px;
        height: 440px;
        @media (max-width: 960px) {
            height: auto;
            padding: 20px 50px;
            margin-bottom: 48px;
        }
        @media (max-width: 767px) {
            padding: 30px 40px;
            height: auto;
        }
        @media (max-width: 540px) {
            padding: 30px;
            flex-direction: column;
            svg {
                max-width: 100% !important;
                max-height: 200px !important;
                margin-bottom: 30px;
                order: 1 !important;
            }
            .text {
                order: 2 !important;
                padding: 0 !important;
                p,
                h3,
                .editable-wrapper {
                    text-align: center;
                }
            }
        }

        .text {
            padding: 0 80px 0 0;
            @media (max-width: 960px) {
                padding: 0 60px 0 0;
            }
            @media (max-width: 767px) {
                padding: 0 40px 0 0;
            }
            @media (max-width: 540px) {
                padding: 0;
            }
            a {
                margin-top: 24px;
            }
        }
        svg {
            flex: 1 0 auto;
            @media (max-width: 960px) {
                max-width: 250px;
                height: auto;
            }
            @media (max-width: 767px) {
                max-width: 180px;
            }
        }

        &.even {
            svg {
                order: 1;
            }
            .text {
                order: 2;
                padding: 0 0 0 80px;
                @media (max-width: 980px) {
                    padding: 0 0 0 60px;
                }
                @media (max-width: 767px) {
                    padding: 0 0 0 40px;
                }
                @media (max-width: 540px) {
                    padding: 0;
                }
            }
        }

        &.step-configuration {
            border-color: #2477f9;
            background: ${colors.blueM6};

            .text {
                @media (max-width: 540px) {
                    padding-right: 30px;
                    text-align: center;
                }
            }
            .hand {
                fill: ${colors.blueM6};
            }
            .phone-background {
                fill: ${colors.blueM3};
            }
            @media (max-width: 980px) {
                svg {
                    max-width: 180px;
                }
            }
            @media (max-width: 767px) {
                svg {
                    max-width: 150px;
                }
            }
        }
        &.step-timeSelection {
            border-color: ${colors.orange};
            background: ${colors.orangeM7};
        }
        &.step-electrician {
            border-color: ${colors.purple};
            background: ${colors.purpleM6};
            @media (max-width: 960px) {
                svg {
                    max-height: 250px;
                }
            }
        }
        &.step-installation {
            border-color: ${colors.yellowP1};
            background: ${colors.yellowM5};
            @media (max-width: 960px) {
                svg {
                    max-height: 220px;
                }
            }
        }
        &.step-payment {
            border-color: ${colors.mint};
            background: ${colors.mintM7};
        }
    }
    .bottom-cta {
        padding-top: 16px;
        display: flex;
        justify-content: center;
        @media (max-width: 688px) {
            padding-top: 0px;
        }
        a {
            min-width: 640px;
            @media (max-width: 688px) {
                min-width: 100%;
            }
        }
    }
`;
