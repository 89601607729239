import styled from 'styled-components';

import { Button as EditableButton, config } from '/front';
import AddressSearch, {
    AddressSearchTheme,
} from '/modules/solar/components/search/AddressSearch';
import AddressSearchResult from '/modules/solar/types/AddressSearchResults';
import { goTo } from '/lib/helpers';
import { useSolarJobLocationState } from '/modules/solar/state/solarJobLocationState';
import { CallToAction as CTA, Theme } from './types';
import ButtonType from '/front/types/frontButtonType';
import SolarContactFormButton from './../contact-callback/SolarContactFormButton';
import { colors, effects } from '/css';

interface Props {
    callToAction: CTA;
    button: ButtonType | null;
    onButtonChange: (data: ButtonType) => void;
    theme: Theme;
}
export default function CallToAction({
    callToAction,
    button,
    onButtonChange,
    theme,
}: Readonly<Props>) {
    const setAddress = useSolarJobLocationState((state) => state.setAddress);

    const onSelectAddress = (address: AddressSearchResult) => {
        setAddress(address);
        goTo('/solar/order/configure');
    };

    const cta = callToAction || CTA.SOLAR;

    return (
        <Wrapper className={`theme-${theme}`}>
            {[CTA.SOLAR, CTA.SOLAR_BUTTON, CTA.SOLAR_CONTACT].includes(cta) && (
                <AddressSearch
                    onSelectAddress={onSelectAddress}
                    theme={AddressSearchTheme.DARK}
                />
            )}
            {(cta === CTA.BUTTON || cta === CTA.SOLAR_BUTTON) && (
                <div
                    style={{
                        paddingTop: cta === CTA.SOLAR_BUTTON ? '12px' : '0px',
                    }}
                >
                    <EditableButton
                        className="button"
                        value={button}
                        onChange={onButtonChange}
                        link={config.pageUrl(button)}
                        style={{ width: '100%' }}
                        onDelete={undefined}
                    >
                        {button?.title || 'button'}
                    </EditableButton>
                </div>
            )}
            {cta === CTA.SOLAR_CONTACT && (
                <div style={{ paddingTop: '12px' }}>
                    <SolarContactFormButton buttonText="Book en prat med våre solcelleeksperter" />
                </div>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    &.theme-wide {
    }
    &.theme-narrow {
        .address-search {
            width: 100%;
            border-color: ${colors.purple};
            input {
                background: ${colors.purple};
                text-align: center;
                + svg {
                    left: calc(50% - 120px);
                    path {
                        fill: white;
                    }
                }
                &:focus,
                &:not(:placeholder-shown) {
                    background: transparent;
                    text-align: left;
                    color: ${colors.black};
                    & + svg {
                        left: 12px;
                        path {
                            fill: ${colors.purple};
                        }
                    }
                }
            }
            .clear-button svg path {
                fill: ${colors.black};
            }
        }
    }
    .address-search {
        ul {
            ${effects.shadow};
            margin: -3px 0 0 -3px;
            width: calc(100% + 6px);
            li {
                background: #fff;
                border-bottom: 1px solid ${colors.grey3};
                &:last-child {
                    border: none;
                }
                @media (pointer: fine) {
                    &:hover {
                        opacity: 1;
                        background: #f8f6ff;
                    }
                }
            }
        }
    }
    .contact-button {
    }
`;
