import { useEffect } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';

import Timeline, { TimelineStepProps } from '/components/ui/Timeline';
import { Text, ui } from '/front';
import id from '/front/lib/id';
import { Theme } from './types';
import { colors } from '/css';

export default function EditableTimeline({
    steps,
    onChange,
    theme,
}: {
    steps: TimelineStepProps[];
    onChange: (steps: TimelineStepProps[]) => void;
    theme: Theme;
}) {
    const { editMode, FrontActions } = ui();

    const newStep = {
        _key: id(),
        type: '',
        title: '',
        description: '',
    };

    const addStep = () => onChange([...steps, newStep]);

    const removeStep = (index: number) =>
        onChange(
            steps.filter((_: TimelineStepProps, i: number) => i !== index)
        );

    const changeStep = curry((index, key, value) =>
        onChange(
            steps.map((step: TimelineStepProps, i: number) => {
                if (i !== index) return step;
                return { ...step, [key]: value };
            })
        )
    );

    useEffect(() => {
        if (editMode && !steps.length) addStep();
    }, [editMode]);

    const addStepInputProps = (step: TimelineStepProps, i: number) => {
        if (!editMode) return step;

        return {
            ...step,
            title: (
                <Text
                    value={steps[i]?.title as string}
                    onChange={changeStep(i, 'title')}
                    placeholder="Step title"
                    className="heading-medium"
                    tag="h5"
                    multiline={true}
                />
            ),
            description: (
                <Text
                    value={steps[i]?.description as string}
                    onChange={changeStep(i, 'description')}
                    placeholder="Step description"
                    className="paragraph-small color-grey"
                    tag="p"
                    multiline={true}
                />
            ),
            content: (
                <FrontActions
                    onDelete={
                        steps.length === 1 ? undefined : () => removeStep(i)
                    }
                    style={{
                        top: '0px',
                        left: '0px',
                        transform: 'none',
                    }}
                />
            ),
        };
    };

    return (
        <EdtitableTimelineWrapper
            className={`theme-${theme} ${
                theme === 'narrow' ? 'pt-60 mb-80' : ''
            }`}
        >
            <Timeline steps={steps.map(addStepInputProps)} activeStep={null} />
            <FrontActions
                onAdd={addStep}
                align="bottom-left"
                style={{ left: '-2px', bottom: '-8px', width: '40px' }}
            />
        </EdtitableTimelineWrapper>
    );
}

const EdtitableTimelineWrapper = styled.div`
    position: relative;
    .content-col {
        padding-left: 75px;
        margin-left: -75px;
        z-index: 10;
        position: relative;
    }
    > div > div:last-child .content-col {
        padding-bottom: 0;
        margin-bottom: 0 !important;
    }

    &.theme-wide {
        width: calc(50% - 85px);
        @media (min-width: 768px) and (max-width: 1160px) {
            width: 45%;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    &.theme-narrow {
        max-width: 458px;
        margin-left: auto;
        margin-right: auto;
    }
    .paragraph-small,
    .description-text {
        position: relative;
        z-index: 50;
        color: ${colors.grey6};
    }
`;
