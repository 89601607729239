import { HTMLAttributes, forwardRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { effects } from '/css';
import { Text, RichText, ui } from '/front';
import Button from '/components/ui/buttons/Button';
import { richContentIsEmpty } from '/front/lib/helpers';
import { Card } from './types';

interface CardProps extends HTMLAttributes<HTMLElement> {
    ref: any;
    change: (key: string) => (value: any) => void;
    onExpandToggle: () => void;
    card: Card | undefined;
    reset?: any;
}

const InfoCard = forwardRef<HTMLDivElement, CardProps>(
    ({ change, onExpandToggle, card, reset }, ref) => {
        const [expanded, setExpanded] = useState<boolean>(false);

        const { editMode } = ui();

        const toggleMore = () => {
            setExpanded(!expanded);
        };

        useEffect(() => {
            setTimeout(onExpandToggle, editMode ? 50 : 0); // in edit mode we need a delay because rich text editor boot
        }, [expanded]);

        useEffect(() => {
            setExpanded(false);
        }, [reset]);

        const hasContent = card?.content && !richContentIsEmpty(card?.content);

        return (
            <CardWrapper ref={ref} className="info-card">
                <Text
                    value={card?.title || ''}
                    onChange={change('title')}
                    placeholder="Card title"
                    className={`heading-small ${
                        editMode || card?.description || hasContent
                            ? 'mb-16-all'
                            : ''
                    }`}
                    tag="h5"
                    multiline={false}
                    placeholderStyle={{}}
                />
                <Text
                    value={card?.description || ''}
                    onChange={change('description')}
                    placeholder="Card description"
                    className={`card-description ${
                        editMode || hasContent ? 'mb-16-all' : ''
                    }`}
                    tag="p"
                    multiline={true}
                    placeholderStyle={{}}
                />
                {expanded && (
                    <RichText
                        className="rich-text mb-16-all"
                        value={card?.content || []}
                        onChange={change('content')}
                        disabledOptions={['image']}
                    />
                )}
                {(editMode || hasContent) && (
                    <Button
                        size="small"
                        variant="tertiary"
                        onClick={toggleMore}
                    >
                        Les mer
                    </Button>
                )}
            </CardWrapper>
        );
    }
);

InfoCard.displayName = 'InfoCard';

export default InfoCard;

const CardWrapper = styled.div`
    ${effects.shadow};
    padding: 16px;
    border-radius: 12px;
    height: fit-content;
    position: relative;
    z-index: 12;
    background: #fff;
    width: 100%;
    @media (max-width: 767px) {
        text-align: center;
    }
    .card-description,
    .rich-text,
    .rich-text li,
    .rich-text p,
    .rich-text div[data-block='true'] {
        font-size: 20px;
        line-height: 28px;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
`;
