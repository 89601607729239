import curry from 'ramda/src/curry';
import { ui, Row, Col } from '/front';
import { ModuleProps } from '/front/types/frontModuleType';
import { PostCodeCheckerModuleType } from '/components/modules/postCodeCheckerV2/types';
import MarginSetting from '/components/modules/_shared/MarginSetting';

export default function PostCodeCheckerModuleSettings({
    module,
    onChange,
}: ModuleProps<PostCodeCheckerModuleType>) {
    const { FrontSelect, FrontImageInput } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, theme, containerWidth, image } = module.data;

    return (
        <>
            <Row className="mb-24">
                <Col width={[6, 12]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6, 12]}>
                    <FrontSelect
                        value={containerWidth || 'large'}
                        options={['large', 'medium']}
                        onChange={change('containerWidth')}
                        medium
                        style={{ width: '100%' }}
                        label="Container size"
                    />
                </Col>
                <Col width={[6, 12]} className="mb-24">
                    <FrontSelect
                        value={theme || 'simple'}
                        options={['simple', 'image']}
                        onChange={change('theme')}
                        medium
                        style={{ width: '100%' }}
                        label="Theme"
                    />
                </Col>
                {theme === 'image' && (
                    <Col width={[12]} className="mb-24">
                        <FrontImageInput
                            value={image}
                            onChange={change('image')}
                            label="Image"
                            width={275}
                            height={140}
                            cropRatio="29:15"
                        />
                    </Col>
                )}
            </Row>
        </>
    );
}
