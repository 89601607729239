import { memo } from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import Container from '/components/Container';
import { Text } from '/front';
import { SolarHeroModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import { colors, effects } from '/css';
import AddressSearch from '/modules/solar/components/search/AddressSearch';
import AddressSearchResult from '/modules/solar/types/AddressSearchResults';
import { goTo } from '/lib/helpers';
import { useSolarJobLocationState } from '/modules/solar/state/solarJobLocationState';
import SolarContactFormButton from './../contact-callback/SolarContactFormButton';

type Data = SolarHeroModuleType['data'];

export default memo(function HeadingModule({
    module,
    onChange,
}: ModuleProps<SolarHeroModuleType>) {
    const { margin, title, description } = module.data;

    const change =
        <K extends keyof Data>(key: K) =>
        (value: Data[K]) =>
            onChange({ data: { ...module.data, [key]: value } });

    const setAddress = useSolarJobLocationState((state) => state.setAddress);

    const onSelectAddress = (address: AddressSearchResult) => {
        setAddress(address);
        goTo('/solar/order/configure');
    };

    return (
        <Wrapper className={margin}>
            <Container flex direction="column" align="center">
                <Text
                    value={title}
                    onChange={change('title')}
                    placeholder="Title"
                    multiline
                    className="title-text heading-xxl"
                    tag="h1"
                />
                <Text
                    value={description}
                    onChange={change('description')}
                    placeholder="Descriptiion"
                    multiline
                    className="description-text paragraph-large cenetr"
                    tag="p"
                />
                <div className="actions">
                    <AddressSearch onSelectAddress={onSelectAddress} />
                    <SolarContactFormButton buttonText="Book en prat med våre solcelleeksperter" />
                </div>
                <div className="illustration">
                    <Image
                        src="/images/illustrations/chill-dude.svg"
                        alt="Illustration of person chilling"
                        width={1743}
                        height={277}
                    />
                </div>
            </Container>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    background: ${colors.yellowM3};
    position: relative;
    :before {
        content: '';
        width: 100%;
        height: 64px;
        display: block;
        top: -64px;
        left: 0;
        position: absolute;
        background: ${colors.yellowM3};
    }
    > div {
        padding-top: 88px;
        padding-bottom: 277px;
        @media (max-width: 767px) {
            padding-top: 48px;
            padding-bottom: 240px;
        }
    }
    .editable-wrapper {
        width: 100%;
        max-width: 680px;
        &:first-of-type {
            max-width: 1088px;
        }
    }
    .title-text {
        max-width: 1088px;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            margin-bottom: 24px;
        }
    }
    .description-text {
        max-width: 680px;
        width: 100%;
        text-align: center;
        margin-bottom: 64px;
        @media (max-width: 767px) {
            margin-bottom: 48px;
        }
    }
    .actions {
        max-width: 680px;
        width: 100%;
        .address-search {
            margin-bottom: 12px;
            width: 100%;
            input {
                text-align: center;
                + svg {
                    left: calc(50% - 120px);
                }
                &:focus,
                &:not(:placeholder-shown) {
                    background: transparent;
                    text-align: left;
                    color: ${colors.black};
                    & + svg {
                        left: 12px;
                        path {
                            fill: ${colors.black};
                        }
                    }
                }
            }
            .clear-button svg path {
                fill: ${colors.black};
            }
            ul {
                ${effects.shadow};
                margin: -3px 0 0 -3px;
                width: calc(100% + 6px);
                li {
                    background: #fff;
                    border-bottom: 1px solid ${colors.grey3};
                    &:last-child {
                        border: none;
                    }
                    @media (pointer: fine) {
                        &:hover {
                            opacity: 1;
                            background: #f8f6ff;
                        }
                    }
                }
            }
        }
        .solar-contact {
            button:hover {
                border-color: ${colors.black};
            }
        }
    }
    .illustration {
        img {
            display: block;
        }
        position: absolute;
        bottom: -2px;
        left: -265px;
        max-width: 1743px;
        width: 1743px;
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 87%;
            background: #fff;
            width: 500px;
            transform: translateX(-100%);
        }
        &:before {
            left: auto;
            right: 0;
            height: 32.4%;
            background: #fff;
            width: 500px;
            transform: translateX(100%);
        }
        @media (max-width: 1370px) {
            left: -165px;
        }
        @media (max-width: 767px) {
            left: -115px;
            max-width: 1400px;
            width: 1400px;
        }
    }
`;
