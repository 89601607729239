import { memo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';

import Container from '/components/Container';
import Button from '/components/ui/buttons/Button';
import ItemCarousel from '/components/layout/ItemCarousel';
import { formatPrice, changeArrayItemPosition } from '/lib/helpers';
import { effects } from '/css';
import { Text, ui, Image, config, error } from '/front';
import ScrollObserver from '/components/ScrollObserver';
import { trackSelectItem, trackViewItemList } from '/lib/ga-data-layer';

export default memo(function PopularJobsModule({
    module,
    onChange,
    addAction,
}) {
    const { items, margin, trackingId = 'Popular job list' } = module.data;

    const { editMode, FrontActions, FrontModal, FrontInput } = ui();

    const [editedJobIndex, setEditedJobIndex] = useState(null);

    const newItem = {
        image: null,
        title: '',
        jobUrl: '',
        job: null,
    };

    const bgdColors = ['#C3F2EE', '#DCFBD5', '#EBE8FE', '#FFD8BF'];

    useEffect(() => {
        if (editMode && !items.length) addItem();
    }, [editMode]);

    useEffect(() => {
        if (editMode) addAction('onAdd', addItem);
    }, [module.data.items, editMode]); // update action everythime module items change to ensure access to up to date module data in the callback

    const addItem = () =>
        onChange({
            data: { ...module.data, items: [...module.data.items, newItem] },
        });

    const removeItem = (index) =>
        onChange({
            data: {
                ...module.data,
                items: module.data.items.filter((_, i) => i !== index),
            },
        });

    const changeItem = curry((index, key, value) =>
        onChange({
            data: {
                ...module.data,
                items: items.map((item, i) => {
                    if (i !== index) return item;
                    return { ...item, [key]: value };
                }),
            },
        })
    );

    const moveItems = useCallback(
        (currentIndex, dir) => {
            const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
            const newItems = changeArrayItemPosition(
                items,
                currentIndex,
                newIndex
            );
            onChange({ data: { ...module.data, items: newItems } });
        },
        [module.data.items]
    );

    const fetchModuleData = async () => {
        const { data, error: e } = await config.api.page.renderModule(module);

        if (e) return error(e);

        onChange({ data: { ...module.data, items: data.items } });
    };

    const onVisible = (revealCount) => {
        if (!editMode && revealCount === 1) {
            trackViewItemList(items, trackingId);
        }
    };

    return (
        <ScrollObserver onVisible={onVisible}>
            <Container className={margin}>
                <Heading className="flex" style={{ marginBottom: '16px' }}>
                    <h6
                        className="heading-medium"
                        style={{ marginRight: '16px' }}
                    >
                        Populære jobber
                    </h6>
                    <Button
                        link={{ href: '/nettbutikk/alle', passHref: true }}
                        variant="tertiary"
                        size="small"
                        style={{ position: 'relative', bottom: '-2px' }}
                    >
                        Se alle
                    </Button>
                </Heading>
                <JobItems>
                    <ItemCarousel
                        className="job-carousel"
                        onStage={[3, 2, 1]}
                        itemPixelGap={24}
                    >
                        {items.map((item, index) => (
                            <div
                                className="job-item"
                                key={index}
                                style={{
                                    backgroundColor: bgdColors[index % 4],
                                }}
                            >
                                <div className="item-title p-32">
                                    <Text
                                        value={item.title}
                                        onChange={changeItem(index)('title')}
                                        placeholder="Job Title"
                                        multiline
                                        className={`heading-large`}
                                        tag="div"
                                    />
                                </div>
                                <div className="item-image-link">
                                    <Button
                                        onClick={() => {
                                            if (!editMode) {
                                                trackSelectItem(
                                                    item.job,
                                                    trackingId,
                                                    index
                                                );
                                            }
                                        }}
                                        link={{
                                            href: item.jobUrl || '#',
                                            passHref: true,
                                        }}
                                    >{`Fra ${formatPrice(
                                        item.job?.startingPrice?.gross
                                    )}`}</Button>
                                    <Image
                                        image={item.image}
                                        src={config
                                            .imageUrl(item.image)
                                            .width(370)
                                            .height(185)
                                            .url()}
                                        onChange={changeItem(index)('image')}
                                    />
                                </div>
                                <FrontActions
                                    align="top-left"
                                    onMoveLeft={
                                        index
                                            ? () => moveItems(index, -1)
                                            : null
                                    }
                                    onMoveRight={
                                        index < items.length - 1
                                            ? () => moveItems(index, 1)
                                            : null
                                    }
                                    onEdit={() => setEditedJobIndex(index)}
                                    onDelete={
                                        items.length > 1
                                            ? () => removeItem(index)
                                            : null
                                    }
                                />
                            </div>
                        ))}
                    </ItemCarousel>
                </JobItems>
                {editedJobIndex !== null && (
                    <FrontModal
                        onClose={() => {
                            setEditedJobIndex(null);
                            fetchModuleData();
                        }}
                        width="500px"
                        title={
                            items[editedJobIndex]?.title || editedJobIndex + 1
                        }
                    >
                        <FrontInput
                            value={items[editedJobIndex]?.jobUrl || ''}
                            onChange={changeItem(editedJobIndex)('jobUrl')}
                            label="Job link"
                            medium
                        />
                    </FrontModal>
                )}
            </Container>
        </ScrollObserver>
    );
});

const Heading = styled.div`
    @media (max-width: 767px) {
        h6 {
            font-size: 28px;
            line-height: 1;
        }
        a {
            top: -2px;
        }
    }
`;

const JobItems = styled.div`
    .item-carousel {
        .job-item {
            height: 100%;
            border-radius: 12px;
            display: block;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: box-shadow 0.2s ease-in-out;
            user-drag: none;
            height: 370px;
            overflow: hidden;
            @media (max-width: 767px) {
                height: 309px;
            }
            &.last {
                ${effects.shadow};
                background: #fff;
                justify-content: center;
            }
            .item-title {
                @media (max-width: 767px) {
                    min-height: 50%;
                }
            }
            .item-image-link {
                position: relative;
                a {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
                img {
                    display: block;
                    user-drag: none;
                    margin: 0 auto;
                }
            }
        }
    }
`;
