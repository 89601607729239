import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<SVGElement> {
    size?: number;
    color?: string;
}

function EnovaIcon({ size = 24, ...props }: Props) {
    return (
        <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
            <path
                d="M12.75 3.49962C9.06563 3.49962 5.94844 5.91368 4.88906 9.24181C6.46406 8.44493 8.24062 7.99962 10.125 7.99962H14.25C14.6625 7.99962 15 8.33712 15 8.74962C15 9.16212 14.6625 9.49962 14.25 9.49962H13.5H10.125C9.34687 9.49962 8.59219 9.58868 7.86094 9.75275C6.64687 10.0293 5.52188 10.5215 4.51406 11.1918C1.79531 13.0059 0 16.1043 0 19.6246V20.3746C0 20.9981 0.501562 21.4996 1.125 21.4996C1.74844 21.4996 2.25 20.9981 2.25 20.3746V19.6246C2.25 17.3418 3.22031 15.2887 4.77188 13.8496C5.7 17.3887 8.92031 19.9996 12.75 19.9996H12.7969C18.9891 19.9668 24 13.8637 24 6.34025C24 4.34337 23.6484 2.44493 23.0109 0.733997C22.8891 0.41056 22.4156 0.424623 22.2516 0.72931C21.3703 2.37931 19.6266 3.49962 17.625 3.49962H12.75Z"
                fill="black"
            />
        </Svg>
    );
}

const Svg = styled.svg<{ color?: string }>`
    path {
        fill: ${(p) => p.color || 'black'};
    }
`;

export default EnovaIcon;
