import { ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import ArrowIcon from '../icons/ArrowIcon';
import CheckmarkSimpleIcon from '../icons/CheckmarkSimpleIcon';
import Heading from '/components/ui/Heading';
import Paragraph from '/components/ui/Paragraph';
import { colors } from '/css';
import { mobile } from '/css/breakpoints';

export interface TimelineStepProps {
    type: any;
    title: string | ReactNode;
    description: string | ReactNode;
    descriptionColor?: string;
    completed?: boolean;
    active?: boolean;
    isLast?: boolean;
    content?: ReactNode;
}
export const TimelineStep = forwardRef<HTMLDivElement, TimelineStepProps>(
    (
        {
            title,
            description,
            completed = false,
            active = false,
            isLast = false,
            content,
            descriptionColor,
        },
        ref
    ) => {
        return (
            <Step
                lastStep={isLast}
                active={active}
                completed={completed}
                descriptionColor={descriptionColor}
                ref={ref}
            >
                <div className="graphics-col">
                    <div className="circle">
                        {active && <ArrowIcon direction="right" size={20} />}
                        {completed && (
                            <CheckmarkSimpleIcon size={20} color="white" />
                        )}
                    </div>
                    <div className="line" />
                </div>
                <div className="content-col mb-48">
                    {typeof title === 'string' ? (
                        <Heading medium tag="h5">
                            {title}
                        </Heading>
                    ) : (
                        title
                    )}
                    {typeof description === 'string' ? (
                        <Paragraph
                            small
                            className="color-grey description-text"
                        >
                            {description}
                        </Paragraph>
                    ) : (
                        description
                    )}
                    {content}
                </div>
            </Step>
        );
    }
);

TimelineStep.displayName = 'TimelineStep';

export type TimelineStep = Omit<TimelineStepProps, 'isLast' | 'active'>;

interface TimelineProps {
    steps: TimelineStep[];
    activeStep: any;
    activeStageRef?: React.RefObject<HTMLDivElement>;
}

const Timeline = ({
    steps,
    activeStep,
    activeStageRef,
    ...props
}: TimelineProps) => {
    const activeIndex = steps.findIndex((a) => a.type === activeStep);
    return (
        <div {...props}>
            {steps.map((step, i) => (
                <TimelineStep
                    key={i}
                    title={step.title}
                    description={step.description}
                    isLast={i === steps.length - 1}
                    completed={i < activeIndex}
                    active={step.type === activeStep}
                    content={step.content}
                    type={step.type}
                    descriptionColor={step.descriptionColor}
                    ref={step.type === activeStep ? activeStageRef : null}
                />
            ))}
        </div>
    );
};

const Step = styled.div<{
    lastStep: boolean;
    active: boolean;
    completed: boolean;
    descriptionColor?: string;
}>`
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-column-gap: 40px;

    ${mobile} {
        grid-column-gap: 24px;
    }

    .graphics-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .circle {
            display: block;
            width: 32px;
            height: 32px;
            border: 9px solid ${colors.purpleM4};
            background-color: white;
            border-radius: 16px;
            position: relative;
            z-index: 2;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            ${(p) =>
                p.active &&
                css`
                    border: none;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                `};

            ${(p) =>
                p.completed &&
                css`
                    background-color: ${colors.purple};
                    border-color: ${colors.purple};
                `};
        }

        .line {
            width: 9px;
            background-color: ${colors.purpleM4};
            height: 100%;
            position: absolute;
            z-index: 1;
            height: calc(100% - 16px);
            bottom: -2px;

            ${(p) =>
                p.completed &&
                css`
                    background-color: ${colors.purple};
                `};

            ${(p) =>
                p.lastStep &&
                css`
                    display: none;
                `};
        }
    }

    .content-col {
    }

    h5 {
        margin-bottom: 4px;
    }
    .description {
        color: ${colors.grey6};
    }

    ${(p) =>
        !!p.descriptionColor &&
        css`
            .description-text {
                color: ${p.descriptionColor};
            }
        `};

    ${mobile} {
        .description-text {
            color: ${colors.grey6} !important;
        }
    }
`;

export default Timeline;
