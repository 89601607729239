import { memo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import Container from '/components/Container';
import { effects, colors } from '/css';
import { Text, ui, Image, config, Link, Row, Col } from '/front';
import { changeArrayItemPosition } from '/lib/helpers';
import { ModuleProps } from '/front/types/frontModuleType';
import { IllustrationCardsModuleType, ItemType } from './types';

type Data = IllustrationCardsModuleType['data'];

const cardTheme: { [key: string]: string } = {
    '#DCFBD5': 'green',
    '#BFB8EF': 'purple',
    '#EAE8FC': 'purpleLight',
    '#CCF1ED': 'mint',
    '#EBF9F8': 'mintLight',
    '#FFD8BF': 'orange',
    '#ffffff': 'white',
    '#FDF2BB': 'yellow',
};

export default memo(function IllustrationCardsModule({
    module,
    onChange,
    addAction,
}: ModuleProps<IllustrationCardsModuleType>) {
    const { items, margin, theme } = module.data;

    const {
        editMode,
        FrontActions,
        FrontColorPicker,
        FrontModal,
        FrontSelect,
    } = ui();

    const [editedItemIndex, setEditedItemIndex] = useState<number | null>(null);

    const newItem: ItemType = {
        image: null,
        title: '',
        link: null,
        background: '#ffffff',
        imageAlignment: 'center',
        ...(theme === 'detailed' ? { description: '' } : {}),
    };

    const changeData =
        <K extends keyof Data>(key: K) =>
        (value: Data[K]) =>
            onChange({ data: { ...module.data, [key]: value } });

    useEffect(() => {
        if (!items.length) addItem();
    }, []);

    useEffect(() => {
        if (editMode) addAction('onAdd', addItem);
    }, [items, editMode]); // update action everythime module items change to ensure access to up to date module data in the callback

    const addItem = () => changeData('items')([...items, newItem]);

    const removeItem = (index: number) =>
        changeData('items')(items.filter((_, i) => i !== index));

    const changeItem =
        (index: number) =>
        <K extends keyof ItemType>(key: K) =>
        (value: ItemType[K]) =>
            changeData('items')(
                items.map((item, i) => {
                    if (i !== index) return item;
                    return { ...item, [key]: value };
                })
            );

    const moveItems = useCallback(
        (currentIndex: number, dir: 1 | -1) => {
            const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
            const newItems = changeArrayItemPosition(
                items,
                currentIndex,
                newIndex
            );
            changeData('items')(newItems);
        },
        [items]
    );

    return (
        <Container
            className={margin}
            medium={theme === 'detailed'}
            style={theme === 'detailed' ? { maxWidth: '880px' } : undefined}
        >
            <Cards className={`theme-${theme || 'default'}`}>
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`card-item has-front-actions card-theme-${
                            cardTheme[item.background]
                        }`}
                        style={{ background: item.background }}
                    >
                        <div
                            className={[
                                'info',
                                theme === 'detailed'
                                    ? 'pt-48 pl-24 pb-48'
                                    : 'pt-32 pl-32 pb-32',
                            ]
                                .filter((c) => c)
                                .join(' ')}
                        >
                            <Text
                                value={item.title}
                                onChange={changeItem(index)('title')}
                                placeholder="Title"
                                className={`heading-${
                                    theme === 'detailed' ? 'medium' : 'large'
                                }`}
                                tag="div"
                            />
                            {theme === 'detailed' && (
                                <Text
                                    value={item.description || ''}
                                    onChange={changeItem(index)('description')}
                                    placeholder="Description"
                                    className={`paragraph-medium item-description`}
                                    tag="div"
                                />
                            )}
                            <Link
                                value={item.link}
                                onChange={changeItem(index)('link')}
                                href={item.link?.url || '#'}
                                underlined
                            />
                        </div>
                        <div
                            className={`image has-front-actions align-${item.imageAlignment}`}
                        >
                            <Image
                                src={config
                                    .imageUrl(item.image)
                                    .width(theme === 'detailed' ? 280 : 240)
                                    .height(theme === 'detailed' ? 245 : 205)
                                    .url()}
                                image={item.image}
                                onChange={changeItem(index)('image')}
                            />
                        </div>
                        <FrontActions
                            onMoveLeft={
                                index ? () => moveItems(index, -1) : undefined
                            }
                            onMoveRight={
                                index < items.length - 1
                                    ? () => moveItems(index, 1)
                                    : undefined
                            }
                            onEdit={() => setEditedItemIndex(index)}
                            onDelete={
                                items.length > 1
                                    ? () => removeItem(index)
                                    : undefined
                            }
                            align="top-left"
                        />
                    </div>
                ))}
            </Cards>
            {editedItemIndex !== null && (
                <FrontModal
                    onClose={() => setEditedItemIndex(null)}
                    width="500px"
                    height="300px"
                    title={items[editedItemIndex]?.title}
                >
                    <Row>
                        <Col width={6} className="mb-24">
                            <FrontColorPicker
                                value={items[editedItemIndex]?.background}
                                onChange={changeItem(editedItemIndex)(
                                    'background'
                                )}
                                medium
                                style={{ width: '100%' }}
                                label="Background color"
                            />
                        </Col>
                        <Col width={6} className="mb-24">
                            <FrontSelect
                                value={items[editedItemIndex]?.imageAlignment}
                                options={[
                                    { name: 'Center', value: 'center' },
                                    { name: 'Top', value: 'top' },
                                    { name: 'Bottom', value: 'bottom' },
                                    {
                                        name: 'Bleed top right',
                                        value: 'bleed-top-right',
                                    },
                                ]}
                                onChange={changeItem(editedItemIndex)(
                                    'imageAlignment'
                                )}
                                medium
                                style={{ width: '100%' }}
                                label="Image alignment"
                            />
                        </Col>
                    </Row>
                </FrontModal>
            )}
        </Container>
    );
});

const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    min-height: 200px;
    .card-item {
        width: calc(50% - 20px);
        margin: 0 10px;
        border-radius: 12px;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: space-between;
        height: 205px;
        .info {
            width: 100%;
            padding-right: 20px;
            .heading-large {
                margin-bottom: 16px;
            }
            a:after {
                background-color: ${colors.orange};
            }
        }
        .image {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &.align-top {
                align-items: flex-start;
            }
            &.align-bottom {
                align-items: flex-end;
            }
            &.align-bleed-top-right {
                align-items: flex-start;
                margin-top: -24px;
                margin-right: -30px;
            }
            img {
                display: block;
                min-width: 240px;
                max-width: 240px;
                width: 240px;
            }
        }
        &.card-theme-white {
            ${effects.shadow};
            .info a:after {
                background-color: ${colors.orange};
            }
        }
        &.card-theme-green {
            .info a:after {
                background-color: ${colors.green};
            }
        }
        &.card-theme-mintLight {
            .info a:after {
                background-color: ${colors.mint};
            }
        }
        &.card-theme-purpleLight {
            .info a:after {
                background-color: ${colors.purple};
            }
        }
        @media (max-width: 1024px) {
            .image {
                img {
                    min-width: 160px;
                    max-width: 160px;
                    width: 160px;
                }
            }
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    @media (max-width: 1024px) {
        min-height: 160px;
        .card-item {
            min-height: 160px;
            height: auto;
        }
    }

    &.theme-detailed {
        margin: 0;
        .card-item {
            width: 100%;
            margin: 0 0 24px 0;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: auto;
            @media (max-width: 767px) {
                flex-wrap: wrap;
            }
            .info {
                padding-right: 80px;
                @media (max-width: 767px) {
                    padding: 12px 24px 24px 24px;
                    width: 100%;
                    order: 2;
                    text-align: center;
                }
                .item-description {
                    margin-bottom: 16px;
                    margin-top: 8px;
                }
            }
            .image {
                img {
                    min-width: 280px;
                    max-width: 280px;
                    width: 280px;
                }
                @media (max-width: 767px) {
                    img {
                        min-width: 160px;
                        max-width: 160px;
                        width: 160px;
                    }
                    order: 1;
                    display: block;
                    height: 140px;
                    margin: 24px auto 0;
                }
            }
        }
    }
`;
