import { memo, MouseEvent } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';
import Link from 'next/link';

import Container from '/components/Container';
import { Text, Image, config, ui, Button } from '/front';
import { effects, colors } from '/css';
import ItemCarousel from '/components/layout/ItemCarousel';
import { formatPrice, changeArrayItemPosition } from '/lib/helpers';
import { ModuleProps } from '/front/types/frontModuleType';
import { JobModuleType, Product } from './types';
import ButtonType from '/front/types/frontButtonType';

export default memo(function JobModule({
    module,
    onChange,
}: ModuleProps<JobModuleType>) {
    const { editMode, FrontActions } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        title,
        description,
        margin,
        background,
        backgroundSecondary,
        image,
        job,
        selectedProducts,
        button,
        textColor,
    } = module.data;

    const mainImageSrc = !image ? '' : config.imageUrl(image).width(700).url();

    const products = selectedProducts
        .map((productPageId) => {
            return (job?.products || []).find(
                (prod) => prod.productPage === productPageId
            );
        })
        .filter((p) => p) as Product[];

    const buttonProps = {
        ...(button || {}),
        url: job ? `/bestilling/jobb/${job.slug}` : `#`,
        title: `Fra ${formatPrice(job?.startingPrice?.gross)}`,
        target: '_self',
    };

    const onMoveLeft = (index: number) => {
        if (index)
            return (e: MouseEvent) => {
                e.preventDefault();
                change('selectedProducts')(
                    changeArrayItemPosition(selectedProducts, index, index - 1)
                );
            };
    };

    const onMoveRight = (index: number) => {
        if (index < products.length - 1)
            return (e: MouseEvent) => {
                e.preventDefault();
                change('selectedProducts')(
                    changeArrayItemPosition(selectedProducts, index, index + 1)
                );
            };
    };

    return (
        <Wrapper className={margin}>
            <div style={{ backgroundColor: background }}>
                <Container>
                    <div className="info">
                        <div
                            className={`info-text pt-60 text-color-${textColor} pb-60`}
                        >
                            <Text
                                value={title}
                                onChange={change('title')}
                                placeholder="Title"
                                multiline
                                className={`heading-large`}
                                tag="h4"
                            />
                            <Text
                                value={description}
                                onChange={change('description')}
                                placeholder="Description"
                                multiline
                                className={`paragraph-medium mb-32`}
                                tag="p"
                            />
                            <div className="button-container">
                                <Button
                                    className="button"
                                    value={buttonProps}
                                    onChange={(val: ButtonType) =>
                                        onChange({
                                            data: {
                                                ...module.data,
                                                button: val,
                                            },
                                        })
                                    }
                                    link={{
                                        ...config.pageUrl(buttonProps),
                                        passHref: true,
                                    }}
                                    target={buttonProps.target || '_self'}
                                    linkPickerProps={{
                                        titleReadOnly: true,
                                        pageSelect: false,
                                        urlReadOnly: true,
                                    }}
                                    onDelete={undefined}
                                >
                                    {buttonProps.title}
                                </Button>
                            </div>
                        </div>
                        <div
                            className="image"
                            style={{
                                backgroundColor: 'rgba(0,0,0,.1)',
                                backgroundImage: mainImageSrc
                                    ? `url(${mainImageSrc})`
                                    : 'none',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                            }}
                        >
                            {editMode && (
                                <Image
                                    image={image}
                                    onChange={change('image')}
                                />
                            )}
                        </div>
                    </div>
                </Container>
            </div>
            <div
                className="products pt-60 pb-60"
                style={{ backgroundColor: backgroundSecondary }}
            >
                <Container>
                    <ItemCarousel
                        className="product-carousel"
                        onStage={[3, 2, 1]}
                        itemPixelGap={24}
                    >
                        {products.map((prod, index) => (
                            <Link
                                prefetch={false}
                                key={prod.productPage}
                                href={`/bestilling/jobb/${job?.slug}?prod=${prod.productPage}`}
                                passHref
                                className="product pt-24 pr-32 pb-48 pl-32"
                                onDragStart={(e) => e.preventDefault()}
                            >
                                <div>
                                    <img
                                        src={
                                            prod.imageUrl + '?auto=format&h=200'
                                        }
                                        alt="product image"
                                    />
                                    <div className="title mb-16">
                                        {prod.title}
                                    </div>
                                    <div className="description mb-24">
                                        {prod.description}
                                    </div>
                                </div>
                                <div>
                                    <span className="link">Bestill nå</span>
                                </div>
                                <FrontActions
                                    onMoveLeft={onMoveLeft(index)}
                                    onMoveRight={onMoveRight(index)}
                                    align="center"
                                />
                            </Link>
                        ))}
                    </ItemCarousel>
                </Container>
            </div>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    .info {
        display: flex;
        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
        .info-text {
            min-width: 50%;
            padding-right: 50px;
            @media (max-width: 767px) {
                min-width: 100%;
                padding-right: 0px;
            }
            .heading-large {
                margin-bottom: 16px;
            }
            .paragraph-medium {
            }
            .button-container {
                position: relative;
                display: inline-block;
                @media (max-width: 767px) {
                    display: block;
                    .button {
                        width: 100%;
                    }
                }
            }
            &.text-color-black {
                .heading-large,
                .paragraph-medium {
                    color: #1f1a22;
                }
            }
            &.text-color-white {
                .heading-large,
                .paragraph-medium {
                    color: #ffffff;
                }
            }
        }
        .image {
            min-width: calc(50% + 140px);
            img {
                display: block;
            }
            @media (max-width: 767px) {
                min-width: calc(100% + (2 * 24px));
                margin-left: -24px;
                height: 204px;
            }
        }
    }
    .products {
        .product-carousel {
            .product {
                height: 100%;
                ${effects.shadow};
                background: #fff;
                border-radius: 12px;
                display: block;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                transition: box-shadow 0.2s ease-in-out;
                user-drag: none;
                overflow: hidden;
                @media (pointer: fine) {
                    &:hover {
                        ${effects.shadowHover};
                    }
                }
                img {
                    display: block;
                    margin-bottom: 8px;
                    user-drag: none;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    max-width: 600px;
                }
                .title {
                    font-size: 26px;
                    font-family: 'gellix-semi';
                    line-height: 32px;
                }
                .description {
                    font-size: 15px;
                    line-height: 23px;
                    color: ${colors.grey6};
                    text-align: center;
                }
                .link {
                    font-size: 20px;
                    font-family: 'gellix-semi';
                    border-bottom: 3px solid ${colors.orange};
                    padding-bottom: 2px;
                    @media (pointer: fine) {
                        &:hover {
                            border-bottom: 3px solid ${colors.purple};
                        }
                    }
                }
                @media (max-width: 767px) {
                    padding-bottom: 32px;
                }
            }
        }
    }
`;
