import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import { colors } from '/css';
import EnovaIcon from '/components/icons/EnovaIcon';
import spreadPropsClassName from '/lib/utils/spreadPropsClassName';

interface Props extends HTMLAttributes<HTMLDivElement> {
    type: 'new' | 'enova' | 'image';
    imageSrc?: string;
}

export default function JobBadge({ type, imageSrc, ...rest }: Props) {
    const props = spreadPropsClassName(rest, `badge-${type}`);
    switch (type) {
        case 'new':
            return <Badge {...props}>Ny</Badge>;
        case 'enova':
            return (
                <Badge {...props}>
                    <EnovaIcon
                        style={{
                            marginRight: 4,
                            width: 10,
                            height: 10,
                        }}
                    />
                    Enova
                </Badge>
            );
        case 'image':
            return (
                <Badge {...props}>
                    <img src={imageSrc} alt="Job badge image" />
                </Badge>
            );
        default:
            return null;
    }
}

const Badge = styled.div`
    height: 26px;
    padding: 8px;
    border-radius: 13px;
    width: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: var(--font-semibold);
    margin: 0 0 6px 6px;
    border: 2px solid transparent;
    &.badge-new {
        background: ${colors.purple};
        color: #fff;
    }
    &.badge-enova {
        background: ${colors.greenM5};
    }
    &.badge-image {
        padding: 2px 6px;
        border-color: ${colors.grey1};
        background: #fff;
        img {
            height: 22px;
            width: auto;
        }
    }
`;
