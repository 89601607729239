import { memo, useEffect, useCallback } from 'react';
import curry from 'ramda/src/curry';
import styled from 'styled-components';

import Container, { ContainerSizes } from '/components/Container';
import Toggle from '/components/ui/Toggle';
import Divider from '/components/ui/Divider';
import { ui, RichText, Text, Link } from '/front';
import { changeArrayItemPosition } from '/lib/helpers';
import id from '/front/lib/id';
import { TogglesModuleType, Item, Theme } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import { colors } from '/css';

type Data = TogglesModuleType['data'];

export default memo(function TogglesModule({
    module,
    onChange,
    addAction,
}: ModuleProps<TogglesModuleType>) {
    const {
        margin,
        items,
        listTitle,
        title,
        containerSize,
        listSubtitle,
        subtitle,
        theme,
        withReadMoreLink,
        readMoreLink,
    } = module.data;

    const { editMode, FrontActions } = ui();

    const changeData =
        <K extends keyof Data>(key: K) =>
        (value: Data[K]) =>
            onChange({ data: { ...module.data, [key]: value } });

    const emptyItem: Item = {
        _key: id(),
        title: '',
        content: '',
    };

    useEffect(() => {
        if (editMode && !items.length) addItem();
    }, [editMode]);

    useEffect(() => {
        if (editMode) addAction('onAdd', addItem);
    }, [module.data, editMode]); // update action everythime module items change to ensure access to up to date module data in the callback

    const addItem = () =>
        changeData('items')([...module.data.items, emptyItem]);

    const changeItem = curry((index, key, value) =>
        changeData('items')(
            items.map((item, i) => {
                if (i !== index) return item;
                return { ...item, [key]: value };
            })
        )
    );

    // to provide item keys for content prevoiusly saved without item keys
    const addMissingKeys = (items: Item[]) =>
        items.map((item) => ({ ...item, _key: item._key || id() }));

    const moveItems = useCallback(
        (currentIndex: number, dir: 1 | -1) => {
            const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
            const newItems = changeArrayItemPosition(
                items,
                currentIndex,
                newIndex
            );
            changeData('items')(addMissingKeys(newItems));
        },
        [items]
    );

    const removeItem = (index: number) => {
        changeData('items')(items.filter((_, i) => i !== index));
    };

    return (
        <Wrapper
            className={`${margin} theme-${theme} ${
                theme === Theme.PADDED_BACKGROUND ? 'pt-120 pb-120' : ''
            }`}
        >
            <Container size={containerSize}>
                {listSubtitle && (
                    <Text
                        value={subtitle || ''}
                        onChange={changeData('subtitle')}
                        placeholder="Subtitle"
                        className={`subtitle`}
                        tag="div"
                    />
                )}
                {listTitle && (
                    <Text
                        value={title || ''}
                        onChange={changeData('title')}
                        placeholder="Title"
                        className={`list-title heading-${
                            theme === Theme.PADDED_BACKGROUND
                                ? 'xl mb-80'
                                : 'large mb-48'
                        }`}
                        tag="div"
                    />
                )}
                {items.map((item, index) => (
                    <ToggleWrapper key={item._key || index}>
                        <Toggle
                            title={
                                <Text
                                    value={item.title}
                                    onChange={changeItem(index)('title')}
                                    placeholder="Toggle title"
                                    className={`heading-medium`}
                                    tag="div"
                                />
                            }
                        >
                            <ContentWrapper className="p-24">
                                <RichText
                                    className="rich-text"
                                    value={item.content}
                                    onChange={changeItem(index)('content')}
                                    editable={editMode}
                                    disabledOptions={['image']}
                                />
                            </ContentWrapper>
                        </Toggle>
                        <Divider className="mt-24 mb-24" />
                        <FrontActions
                            onMoveUp={
                                index ? () => moveItems(index, -1) : undefined
                            }
                            onMoveDown={
                                index < items.length - 1
                                    ? () => moveItems(index, 1)
                                    : undefined
                            }
                            onDelete={
                                items.length <= 1
                                    ? undefined
                                    : () => removeItem(index)
                            }
                            align="top-right"
                        />
                    </ToggleWrapper>
                ))}
                {withReadMoreLink && (
                    <div className="read-more">
                        <Link
                            value={readMoreLink || null}
                            onChange={changeData('readMoreLink')}
                            href={readMoreLink?.url || '#'}
                            underlined
                        />
                    </div>
                )}
            </Container>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    .subtitle {
        font-family: var(--font-bold);
        text-transform: uppercase;
        color: ${colors.purple};
        font-size: 24px;
        line-height: 29px;
        height: 32px;
        margin-bottom: 12px;
    }
    .read-more {
        padding-top: 40px;
        a {
            &:after {
                background: ${colors.orange};
            }
            @media (pointer: fine) {
                &:hover {
                    &:after {
                        background: ${colors.purple};
                    }
                }
            }
        }
    }
    &.theme-padded-background {
        background: ${colors.grey2};
        .list-title,
        .subtitle {
            text-align: center;
        }
        .read-more {
            text-align: center;
        }
        .toggle-button {
            border-color: transparent !important;
        }
        .details > div {
            padding: 0;
        }
    }
`;

const ToggleWrapper = styled.div`
    .editable-wrapper {
        width: calc(100% - 95px);
    }
    > .front-actions {
        top: 0px;
        right: 64px;
    }
    .title .heading-medium {
        font-family: 'gellix-semi';
        font-size: 24px;
        line-height: 28px;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 20px;
            line-height: 28px;
        }
        @media (max-width: 767px) {
            font-size: 17px;
            line-height: 22px;
            white-space: initial !important;
        }
    }
`;

const ContentWrapper = styled.div`
    margin-top: 12px;
    width: 100%;
    border-radius: 12px;
    background: #f5f5f7;
    p,
    div[data-block='true'],
    ul li,
    ol li {
        font-size: 20px;
        line-height: 28px;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 17px;
            line-height: 26px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 23px;
        }
    }
`;
