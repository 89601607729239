import styled from 'styled-components';

import { colors } from '/css';
import Illustration from './Illustration';
import Heading from '/components/ui/Heading';
import Paragraph from '/components/ui/Paragraph';

export default function MoreInfo(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <Wrapper>
            <div className="text">
                <Heading large className="mb-24 title-text">
                    Bestill før nyttår og spar{' '}
                    <span>
                        15%
                        <svg
                            width="74"
                            height="15"
                            viewBox="0 0 74 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.99992 11.3255C14.4998 7.32377 39.5 2.8252 70.9999 3.82661"
                                stroke="#44D7CB"
                                stroke-width="6"
                                stroke-linecap="round"
                            />
                        </svg>
                    </span>
                </Heading>
                <Paragraph medium className="mb-24">
                    Vi i SpotOn mener solceller spiller en viktig rolle i det
                    grønne skiftet og gir derfor 15% rabatt på solceller ved
                    bestilling før nyttår.
                </Paragraph>
                <Paragraph medium>
                    Legg inn din bestilling. Etter kontrollmåling trekker vi fra
                    15% av totalkostnaden på ditt tilbud.
                </Paragraph>
            </div>
            <div className="illustration">
                <Illustration />
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 600px;
    border-radius: 12px;
    border: 3px solid ${colors.mint};
    width: 1160px;
    max-width: calc(100% - 48px);
    background: ${colors.mintM4};
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
    .text {
        padding: 44px 20px 40px 56px;
        @media (max-width: 767px) {
            padding: 24px 32px 0 32px;
        }
        .title-text span {
            position: relative;
            svg {
                position: absolute;
                bottom: -10px;
                right: -3px;
                @media (max-width: 767px) {
                    transform: scale(0.7);
                    right: -10px;
                }
            }
        }
    }
    .illustration {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        @media (max-width: 767px) {
            justify-content: center;
        }
        svg {
            margin-right: -23px;
            margin-bottom: 0px;
            @media (max-width: 767px) {
                margin-right: 0;
                width: 300px;
                height: 237px;
            }
        }
    }
`;
