import { memo, useMemo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import debounce from 'lodash.debounce';

import Container from '/components/Container';
import Button from '/components/ui/buttons/Button';
import Icon from '/components/ui/Icon';
import LegacyInput from '../../ui/LegacyInput';
import { Text, ui, config, error } from '/front';
import HorisontalScroller from '/components/HorisontalScroller';
import { effects, colors } from '/css';
import jobTypeApi from '/api/jobType';
import { useOnClickOutside, userIs } from '/lib/helpers';
import JobCard from '/components/layout/JobCard';
import { useUserState } from '/store/userStore';
import useIsJobSupported from '/hooks/useIsJobSupported';

let timeout;

const CategoryItem = ({
    category,
    categoryLink,
    activeCategoryId,
    className = null,
}) =>
    !category ? null : (
        <Link
            prefetch={false}
            href={categoryLink}
            passHref
            onClick={(e) =>
                activeCategoryId === category.value ? e.preventDefault() : null
            }
            className={`${className} category-item ${
                activeCategoryId === category.value ? 'active' : ''
            }`}
        >
            <div className="category-icon">
                <img
                    src={category.icon?.url || 'https://dummyimage.com/72x58'}
                    alt={category.icon?.alt || 'category icon'}
                />
            </div>
            <div className="category-name">{category.name}</div>
        </Link>
    );

export default memo(function JobCategoriesModule({ module, onChange }) {
    const { title, margin, topPadding, subtitle, activeCategoryId, theme } =
        module.data;

    const { editMode } = ui();

    const user = useUserState((state) => state.user);

    const searchInputRef = useRef();
    const mobileCategoryViewRef = useRef();
    const moduleContainerRef = useRef();
    const categoryDrawerRef = useRef();

    const [search, setSearch] = useState('');
    const [searchMode, setSearchMode] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [fetchingResults, setFetchingResults] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [categories, setCategories] = useState(module.data.categories || []);
    const [mobileCategoryViewFixed, setMobileCategoryViewFixed] =
        useState(false);
    const [categoryDrawerVisible, setCategoryDrawerVisible] = useState(false);
    const [mobileSearchTopSpacing, setMobileSearchTopSpacing] = useState(0);
    const [mobileSearchInputExpanded, setMobileSearchInputExpanded] =
        useState(false);

    const areSearchResults = searchResults.length > 0;

    useEffect(() => {
        if (!editMode) return;

        (async () => {
            const { data, error: e } = await config.api.page.renderModule(
                module
            );
            if (e) return error(e);
            setCategories(data.categories || []);
        })();
    }, [activeCategoryId]);

    useEffect(() => {
        if (!search) {
            clearTimeout(timeout);
            if (searchResults.length) setSearchResults([]);
            return;
        }

        if (jobs.length) {
            setSearchResults(jobs.filter(jobMatchingSearch));
            return;
        }

        clearTimeout(timeout);
        timeout = setTimeout(async () => {
            const jobs = await jobTypeApi.index();
            setFetchingResults(false);
            if (jobs.data) {
                const publishedJobs = jobs.data.filter((j) => j.published);
                setJobs(publishedJobs);
                setSearchResults(publishedJobs.filter(jobMatchingSearch));
            }
        }, 300);
    }, [search]);

    useEffect(() => {
        const onKeyup = (e) =>
            searchMode && e.key === 'Escape' ? exitSearchMode() : null;
        window.addEventListener('keyup', onKeyup);
        return () => {
            window.removeEventListener('keyup', onKeyup);
            document.getElementsByTagName('html')[0].classList.remove('oh');
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', debounce(onScroll, 10));
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const onScroll = (e) => {
        if (!mobileCategoryViewRef.current || window.innerWidth > 767) return;

        const mcvBCR = mobileCategoryViewRef.current.getBoundingClientRect();
        const mBCR = moduleContainerRef.current.getBoundingClientRect();
        if (mcvBCR.top <= 0) setMobileCategoryViewFixed(true);
        if (mBCR.y > 0) setMobileCategoryViewFixed(false);
    };

    useOnClickOutside(categoryDrawerRef, (e) => {
        if (e.target?.classList.contains('category-dropdown-trigger')) return;
        categoryDrawerVisible && toggleCategoryDrawer();
    });

    const toggleCategoryDrawer = () => {
        if (!categoryDrawerVisible) {
            document.getElementsByTagName('html')[0].classList.add('oh');
        } else {
            document.getElementsByTagName('html')[0].classList.remove('oh');
        }
        setCategoryDrawerVisible(!categoryDrawerVisible);
    };

    const jobMatchingSearch = (job) =>
        job.jobTypeVersion.name.toLowerCase().includes(search.toLowerCase());

    const enterSearchMode = () => {
        setSearchMode(true);
        document.getElementsByTagName('html')[0].classList.add('oh');
        setTimeout(() => {
            searchInputRef?.current.focus();
        }, 500);
    };

    const enterMobileSearchMode = () => {
        setSearchMode(true);
        document.getElementsByTagName('html')[0].classList.add('oh');
        const mcvBCR = mobileCategoryViewRef.current.getBoundingClientRect();
        setMobileSearchTopSpacing(mcvBCR.y + mcvBCR.height);
        mobileCategoryViewRef.current.getElementsByTagName('input')[0].focus();
        setMobileSearchInputExpanded(true);
    };

    const exitSearchMode = () => {
        setSearchMode(false);
        setSearch('');
        setCategoryDrawerVisible(false);
        setSearchResults([]);
        document.getElementsByTagName('html')[0].classList.remove('oh');
        setMobileSearchTopSpacing(0);
        setMobileSearchInputExpanded(false);
    };

    const categoryLink = (category) => {
        return category?.pagePath || '/';
    };

    const categoryOptions = categories.map((cat) => ({
        name: cat.name,
        value: cat.id,
        icon: cat.icon,
        pagePath: cat.pagePath,
    }));

    const categoryOptionsByTwo = (options) => {
        const one = options.slice(0, Math.ceil(options.length / 2));
        const two = options.slice(-Math.floor(options.length / 2));
        return one.map((item, i) => [item, two[i] || null]);
    };

    const isJobSupported = useIsJobSupported({ resourceType: 'cart' });

    return (
        <div
            ref={moduleContainerRef}
            style={{
                paddingTop: mobileCategoryViewFixed ? '48px' : '0px',
            }}
        >
            <MobileCategoryView
                ref={mobileCategoryViewRef}
                theme={theme}
                className={[
                    mobileCategoryViewFixed && 'fixed',
                    categoryDrawerVisible && 'category-drawer-visible',
                    mobileSearchInputExpanded && 'search-expanded',
                ]
                    .filter((c) => c)
                    .join(' ')}
            >
                <div className="search-trigger" onClick={enterMobileSearchMode}>
                    <LegacyInput
                        icon="search-glass"
                        name="search-cat"
                        placeholder={`Søk`}
                        value={search}
                        onChange={setSearch}
                        small
                    />
                </div>
                <div
                    className="category-dropdown-trigger"
                    onClick={toggleCategoryDrawer}
                >
                    {categories.find((c) => activeCategoryId === c.id)?.name ||
                        'Kategorier'}{' '}
                    <Icon icon="dropdown-small" />
                </div>
                <Icon
                    icon="close"
                    onClick={exitSearchMode}
                    className="close-search"
                />
            </MobileCategoryView>
            {categoryDrawerVisible && (
                <CategoryDrawerContainer
                    style={{ bottom: editMode ? '42px' : '0px' }}
                >
                    <div className="drawer" ref={categoryDrawerRef}>
                        {categoryOptions.map((category) => (
                            <Link
                                href={categoryLink(category)}
                                passHref
                                key={category.name}
                                onClick={(e) =>
                                    activeCategoryId === category.value
                                        ? e.preventDefault()
                                        : null
                                }
                                className={`${
                                    activeCategoryId === category.value
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                {category.name}
                                <Icon icon="arrow-right" />
                            </Link>
                        ))}
                    </div>
                </CategoryDrawerContainer>
            )}
            <ModuleOuterWrapper className={margin} theme={theme}>
                <Container>
                    <ModuleInnerWrapper theme={theme}>
                        {theme === 'default' && (
                            <div className={`top mb-48 ${topPadding}`}>
                                <Text
                                    value={title}
                                    onChange={(val) =>
                                        onChange({
                                            data: {
                                                ...module.data,
                                                title: val,
                                            },
                                        })
                                    }
                                    placeholder="Title"
                                    multiline
                                    className={`heading-xl`}
                                    tag="h1"
                                />
                                <div className="search-container">
                                    <Text
                                        value={subtitle}
                                        onChange={(val) =>
                                            onChange({
                                                data: {
                                                    ...module.data,
                                                    subtitle: val,
                                                },
                                            })
                                        }
                                        placeholder="Title"
                                        multiline
                                        className={`paragraph-medium`}
                                        tag="p"
                                    />
                                    <Button
                                        variant="tertiary"
                                        size="medium"
                                        icon={<Icon icon="search-glass" />}
                                        className="default-search-button"
                                        onClick={enterSearchMode}
                                    >
                                        Søk på jobber
                                    </Button>
                                </div>
                            </div>
                        )}
                        <HorisontalScroller className="horisontal-scroller">
                            {/* shown on desktop */}
                            {categoryOptions.map((category) => (
                                <CategoryItem
                                    key={category.name}
                                    category={category}
                                    categoryLink={categoryLink(category)}
                                    className="single"
                                    activeCategoryId={activeCategoryId}
                                />
                            ))}
                            {/* shown on mobile */}
                            {categoryOptionsByTwo(categoryOptions).map(
                                (set, i) => (
                                    <div className="set" key={i}>
                                        <CategoryItem
                                            key={set[0]?.name}
                                            category={set[0]}
                                            categoryLink={categoryLink(set[0])}
                                            activeCategoryId={activeCategoryId}
                                        />
                                        <CategoryItem
                                            key={set[1]?.name}
                                            category={set[1]}
                                            categoryLink={categoryLink(set[1])}
                                            activeCategoryId={activeCategoryId}
                                        />
                                    </div>
                                )
                            )}
                        </HorisontalScroller>
                    </ModuleInnerWrapper>
                </Container>
            </ModuleOuterWrapper>
            {searchMode && (
                <SearchOverlay
                    style={{
                        top: mobileSearchTopSpacing + 'px',
                        bottom: editMode ? '42px' : '0px',
                    }}
                >
                    <Container>
                        <div className="search-input mb-48">
                            <input
                                className="pt-48 pb-48"
                                type="text"
                                placeholder="Søk på jobber"
                                ref={searchInputRef}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Button
                                variant="tertiary"
                                size="small"
                                icon={<Icon icon="close" />}
                                onClick={exitSearchMode}
                            >
                                Lukk søk
                            </Button>
                        </div>
                        {!fetchingResults && search && !areSearchResults && (
                            <div className="no-results">
                                <p className="heading">
                                    Ops! Vi finner ikke jobben du søker etter.
                                </p>
                                <p className="paragraph-large mb-32">
                                    Men prøv gratis{' '}
                                    <Link href="/videobefaring" passHref>
                                        videobefaring
                                    </Link>{' '}
                                    med elektriker og vi vil hjelpe deg med det
                                    du trenger.
                                </p>
                                <Button
                                    variant="secondary"
                                    className="book-inspection-button"
                                    link={{ href: '/bestilling/videobefaring' }}
                                >
                                    Book videobefaring
                                </Button>
                            </div>
                        )}
                        {areSearchResults && (
                            <div className="search-results pb-48">
                                {searchResults.map((job) => (
                                    <JobCard
                                        key={job.jobTypeVersion?.slug}
                                        linkProps={{
                                            href: `/bestilling/jobb/${job?.jobTypeVersion.slug}`,
                                        }}
                                        openLinksInNewTab={false}
                                        job={{
                                            typeKey: job?.typeKey,
                                            jobTypeVersion: {
                                                name: job.jobTypeVersion?.name,
                                                image: job.jobTypeVersion
                                                    ?.image,
                                                slug: job.jobTypeVersion?.slug,
                                            },
                                            totals: {
                                                startingPrice:
                                                    job?.totals?.startingPrice,
                                            },
                                        }}
                                        supported={isJobSupported(job?.typeKey)}
                                    />
                                ))}
                            </div>
                        )}
                    </Container>
                </SearchOverlay>
            )}
        </div>
    );
});

const ModuleOuterWrapper = styled.div`
    #checker {
        margin-bottom: ${(props) =>
            props.theme === 'minimal' ? '48px' : '0px'};
    }
`;

const ModuleInnerWrapper = styled.div`
    .top {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
        .heading-xl,
        .editable-wrapper {
            min-width: 60%;
            @media (max-width: 767px) {
                min-width: 100%;
            }
        }
        .default-search-button {
            @media (max-width: 767px) {
                ${(props) => props.theme === 'default' && `display: none;`}
            }
        }
        .search-container {
            text-align: right;
            width: 40%;
            align-self: flex-end;
            @media (max-width: 767px) {
                width: 100%;
                text-align: left;
                padding-top: 32px;
            }
            .paragraph-medium {
                margin-bottom: 18px;
            }
        }
    }
    .horisontal-scroller {
        @media (max-width: 767px) {
            ${(props) => props.theme === 'default' && `display: none;`}
        }
        .single {
            @media (max-width: 767px) {
                ${(props) => props.theme === 'minimal' && `display: none;`}
            }
        }
        .set {
            ${(props) => props.theme === 'default' && `display: none;`}
            @media(min-width: 768px) {
                ${(props) => props.theme === 'minimal' && `display: none;`}
            }
        }
    }
    .category-item {
        margin: 0 13px;
        text-align: center;
        cursor: pointer;
        .category-icon {
            margin: 0 auto 4px;
            width: 72px;
            height: 58px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            background: #fff;
        }
        .category-name {
            font-size: 15px;
            text-align: center;
            font-family: 'gellix-semi';
        }
        @media (pointer: fine) {
            &:hover {
                .category-icon {
                    ${effects.shadow};
                }
                .category-name {
                    color: ${colors.purple};
                }
            }
        }
        &.active {
            cursor: default;
            .category-icon {
                background: #ebe8fe;
            }
            .category-name {
                color: ${colors.purple};
                font-family: gellix-bold;
            }
            @media (pointer: fine) {
                &:hover {
                    .category-icon {
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .set {
        margin: 0 13px;
        .category-item:first-child {
            margin: 0 0 16px 0;
            display: block;
        }
    }
`;

const SearchOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: 767px) {
        padding-top: 24px;
    }
    .search-input {
        position: relative;
        border-bottom: 3px solid #f1f0f3;
        input {
            padding-left: 0;
            font-family: gellix-bold;
            color: ${colors.black};
            font-size: 42px;
            line-height: 56px;
            border: none;
            background: transparent;
            &::placeholder {
                color: #95939e;
                opacity: 1;
            }
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 36px;
                line-height: 46px;
            }
        }
        button {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            padding-bottom: 4px;
            svg {
                margin-bottom: -3px;
            }
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    .search-results {
        display: flex;
        flex-wrap: wrap;
    }
    .no-results {
        width: 57%;
        @media (max-width: 767px) {
            width: 100%;
        }
        .heading {
            font-family: gellix-bold;
            font-size: 60px;
            line-height: 68px;
            margin-bottom: 12px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 48px;
                line-height: 52px;
            }
            @media (max-width: 767px) {
                font-size: 32px;
                line-height: 36px;
            }
        }
        .paragraph-large {
            @media (max-width: 767px) {
                margin-bottom: 32px;
            }
        }
        .book-inspection-button {
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
`;

const MobileCategoryView = styled.div`
    ${(props) =>
        props.theme === 'default' &&
        `
        @media(min-width: 768px) {
            display: none;
        }
    `}
    ${(props) =>
        props.theme === 'minimal' &&
        `
        display: none!important;
    `}
    height: 48px;
    display: flex;
    position: relative;
    &.fixed {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9901;
    }
    &.category-drawer-visible {
        z-index: 9901;
        //margin-top:-16px;
        .category-dropdown-trigger svg {
            transform: rotate(180deg);
            margin-bottom: -4px;
        }
    }
    &.fixed.category-drawer-visible {
        margin-top: 0px;
    }
    .search-trigger,
    .category-dropdown-trigger {
        padding: 0 24px;
        align-items: center;
        display: flex;
        cursor: pointer;
        padding-bottom: 3px;
        background: #fff;
        height: 48px;
        border-top: 3px solid rgba(76, 71, 108, 0.08);
        border-bottom: 3px solid rgba(76, 71, 108, 0.08);
        transition: all 0.2s ease-in-out;
    }
    .search-trigger {
        border-right: 3px solid rgba(76, 71, 108, 0.08);
        min-width: 115px;
        width: 115px;
        padding: 0 0 0 5px;
        transition: all 0.2s ease-in-out;
        input {
            box-shadow: none !important;
            border: none !important;
            border-radius: 0;
            height: 42px !important;
            &::placeholder {
                color: ${colors.black};
                opacity: 1;
            }
        }
    }
    .category-dropdown-trigger {
        width: calc(100% - 115px);
        justify-content: space-between;
        color: ${colors.purple};
        svg {
            margin-right: -4px;
            pointer-events: none;
        }
    }
    .close-search {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
    }
    &.search-expanded {
        .search-trigger {
            min-width: calc(100% + 3px);
            width: calc(100% + 3px);
            border-right-color: transparent;
            input::placeholder {
                color: ${colors.grey6};
            }
        }
        .category-dropdown-trigger {
            transform: translateX(100%);
        }
        .close-search {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const CategoryDrawerContainer = styled.div`
    width: 100%;
    height: calc(100vh);
    background: rgba(0, 0, 0, 0.6);
    z-index: 9900;
    position: fixed;
    bottom: 0;
    .drawer {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #fff;
        border-top-left-radius: 28px;
        border-top-right-radius: 28px;
        padding: 24px;
        visibility: none;
        opacity: 0;
        transfor: translateY(100%);
        animation: slideUp 0.3s ease-in-out;
        animation-fill-mode: forwards;
        @keyframes slideUp {
            from {
                visibility: none;
                opacity: 0;
                transform: translateY(100%);
            }
            to {
                visiblility: visible;
                opacity: 1;
                transform: translateY(0);
            }
        }
        a {
            display: block;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'gellix-bold';
            font-size: 20px;
            line-height: 39px;
            color: #1f1a22;
            svg path {
                fill: ${colors.purple};
            }
            &.active {
                color: ${colors.purple};
            }
        }
    }
`;
