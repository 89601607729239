import settingsApi from '/api/settings';
import { config } from '/front';
import getMenus from '/lib/utils/getMenus';
import CmsPage from '/views/CmsPage';

export const getStaticProps = async () => {
    const settingsResp = await settingsApi.getPublic();
    const pageResp = await config.api.page.get({
        uuid: 'error' in settingsResp ? '' : settingsResp.homePageId,
        published: true,
        type: ['page'],
    });

    return {
        props: {
            page: 'error' in pageResp ? null : pageResp[0],
            menus: await getMenus(),
        },
        revalidate: 60,
    };
};

export default CmsPage;
