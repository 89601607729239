import { ui } from '/front';
import { ContainerSizes } from '/components/Container';
import { capitalizeFirst } from '/lib/helpers';

interface Props {
    containerSize: ContainerSizes;
    onChange: (val: ContainerSizes) => void;
    allowedValues?: ContainerSizes[];
}

export default function ContainerSizeSetting({
    containerSize,
    onChange,
    allowedValues,
}: Props) {
    const { FrontSelect } = ui();

    const options = Object.values(allowedValues || ContainerSizes).map(
        (size) => ({
            value: size,
            name: capitalizeFirst(
                size
                    .split(/(?=[A-Z])/)
                    .map((s) => s.toLowerCase())
                    .join(' ')
            ),
        })
    );

    return (
        <FrontSelect
            value={containerSize || options[0]?.value}
            options={options}
            onChange={onChange}
            medium
            style={{ width: '100%' }}
            label="Container size"
        />
    );
}
